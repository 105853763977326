import { ITNDropdownMenu } from "@life_uikit/uikit/interfaces";
import { computed, defineComponent, PropType } from "vue";

export default defineComponent({
  name: "DropdownMenu",
  props: {
    menuItems: { type: Array as PropType<ITNDropdownMenu[]>, required: true },
    showMenu: { type: Boolean, required: true },
    top: { type: [String, Number], default: "" },
    left: { type: [String, Number], default: "" },
    right: { type: [String, Number], default: "" },
    bottom: { type: [String, Number], default: "" },
    buttonClassName: { type: String, default: "" },
    buttonId: { type: String, default: "" },
    context: Boolean,
    disableWidth: Boolean
  },
  emits: ["close", "select"],
  setup: (props, context) => {
    const style = computed<Partial<CSSStyleDeclaration>>(() => {
      let transformOrigin = "center";

      if (props.left || props.left === "0") {
        transformOrigin = "left";
      } else if (props.right || props.right === "0") {
        transformOrigin = "right";
      }

      if (props.top || props.top === "0") {
        transformOrigin += " top";
      } else if (props.bottom || props.bottom === "0") {
        transformOrigin += " bottom";
      } else {
        transformOrigin += " center";
      }

      return {
        top: props.top ? props.top + "px" : "",
        left: props.left ? props.left + "px" : "",
        right: props.right ? props.right + "px" : "",
        bottom: props.bottom ? props.bottom + "px" : "",
        transformOrigin
      };
    });

    const selectMenu = (itemId: string) => {
      const item = props.menuItems.find(m => m.id === itemId);

      if (item && !item.disabled) {
        context.emit("select", item.id);
      }
    };
    const closeOptionsMenu = (event: PointerEvent) => {
      const target = event?.target as HTMLElement | SVGSVGElement | null;
      const element =
        props.buttonId || props.buttonClassName
          ? document.querySelector(
              props.buttonId
                ? "#" + props.buttonId
                : props.buttonClassName
                  ? "." + props.buttonClassName
                  : ""
            )
          : undefined;

      if (
        target &&
        (target instanceof HTMLElement || target instanceof SVGSVGElement) &&
        (props.context || event.type === "click") &&
        (!props.context || event.type === "contextmenu") &&
        (target.className.includes?.(props.buttonClassName || "empty") ||
          (element && element.contains(target)))
      ) {
        return;
      }
      if (props.showMenu) {
        context.emit("close");
      }
    };

    const vcoConfig = {
      handler: closeOptionsMenu,
      events: ["contextmenu", "click"]
    };

    return {
      style,
      vcoConfig,
      selectMenu,
      closeOptionsMenu
    };
  }
});
