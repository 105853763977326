<template>
  <TNUserPicture
    :text="text"
    :icon="icon"
    :image="image"
    :size="size"
    :icon-size="application ? 48 : 0"
    :square="isAttachmentImage || isAttachmentFile || application"
    class="avatar"
    :class="{
      avatar_application: application,
      avatar_favorite: isFavorite,
      avatar_attachment: isAttachmentImage,
      'avatar_attachment-file': isAttachmentFile,
      avatar_meet: meeting
    }"
    :status="avatarStatus"
  />
</template>

<script lang="ts" src="./avatar.ts" />

<style lang="css" src="./avatar.css" />
