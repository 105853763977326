import { GetSettingsResponse } from "@superapp/life-proto/pkg-ts/tnlife/chat/settings/settings_service_pb";
import { Absence } from "@superapp/life-proto/pkg-ts/tnlife/chat/v3/absence_pb";
import { Attachment } from "@superapp/life-proto/pkg-ts/tnlife/chat/v3/attachment_pb";
import { Avatar } from "@superapp/life-proto/pkg-ts/tnlife/chat/v3/avatar_pb";
import { MessageEntity } from "@superapp/life-proto/pkg-ts/tnlife/chat/v3/message_entity_pb";
import { Message } from "@superapp/life-proto/pkg-ts/tnlife/chat/v3/message_pb";
import {
  Stream as OuterStream
} from "@superapp/life-proto/pkg-ts/tnlife/chat/v3/stream_pb";
import {
  Colleague,
  Person,
  User
} from "@superapp/life-proto/pkg-ts/tnlife/chat/v3/user_pb";
import {
  GetPersonColleaguesResponse,
  GetPersonResponse
} from "@superapp/life-proto/pkg-ts/tnlife/chat/v3/user_service_pb";
import { UserStatus } from "@superapp/life-proto/pkg-ts/tnlife/chat/v3/user_status_pb";
import { format } from "date-fns";
import { ru } from "date-fns/locale/ru";
import semver from "semver/preload";
import { UAParser } from "ua-parser-js";

import {
  appIdFragmentRegex,
  environments,
  LSNames,
  messageLinkRegexString,
  nullUuid,
  threeDays,
  weekDays
} from "@/common/constants";
import { formatLastUpdatedAt, getMessageBody } from "@/common/functions";
import Infrastructure from "@/infrastructure";
import { Electron } from "@/infrastructure/electron";
import { EnvironmentService } from "@/service/options";
import {
  IAbsence,
  IAvatar,
  IColleagues,
  IMutationPopup,
  IUser,
  ITimezone
} from "@/store/i-store";
import {
  Centrifuge,
  IAttachment,
  IMessage,
  IMessageEntity,
  Stream
} from "@/store/modules/stream/i-stream";
import { IPeriodicity } from "@/views/meets/i-meets";
import { IApp, ISite } from "@/views/services/i-services";

import Type = OuterStream.Type;
import SystemType = OuterStream.SystemType;
import Visible = OuterStream.Visible;

const Infra = new Infrastructure();

export interface IEnvironment {
  name: string;
  baseApiEndpoint: string;
  centrifugeEndpoint: string;
  meetUrl: string;
  webUrl: string;
  oneSignalAppId: string;
}

const apiTjDevStart = "https://api-tn-life-tnlife";
const apiTjDevEnd = ".tages.dev";
const wsTjDevStart = "wss://ws2-tn-life-tnlife";
const wsTjDevEnd = ".tages.dev/connection/websocket";

export class MainService {
  findEnvironmentPreset(name: string): IEnvironment {
    const foundEnvironment = environments.find(
      env => env.name.toLowerCase() === name.toLowerCase()
    );

    if (foundEnvironment) {
      return foundEnvironment;
    }

    return {
      name,
      centrifugeEndpoint: `wss://ws2-tn-life-tnlife${name}.tages.dev/connection/websocket`,
      baseApiEndpoint: `https://api-tn-life-tnlife${name}.tages.dev`,
      meetUrl: `https://tn-meet-tnlife${name}.tages.dev/`,
      webUrl: `https://web-tn-life-tnlife${name}.tages.dev`,
      oneSignalAppId: "caacd748-5141-4ae4-ad38-2a43a65874b7"
    };
  }

  parseEnvironment(api: string, ws: string) {
    const environment = environments.find(
      env =>
        env.baseApiEndpoint.toLowerCase() === api.toLowerCase() &&
        env.centrifugeEndpoint.toLowerCase() === ws.toLowerCase()
    );

    if (environment) {
      return environment.name;
    } else if (
      api.startsWith(apiTjDevStart) &&
      api.endsWith(apiTjDevEnd) &&
      ws.startsWith(wsTjDevStart) &&
      ws.endsWith(wsTjDevEnd)
    ) {
      return (
        api.replace(apiTjDevStart, "").replace(apiTjDevEnd, "") || "custom"
      );
    }

    return "custom";
  }

  setEnvironment(env: IEnvironment): void {
    if (this.isBrowser()) return;
    localStorage.setItem(LSNames.Environment, JSON.stringify(env));
    if (env.name === "custom") return;
    const savedEnvList: string[] = JSON.parse(
      localStorage.getItem(LSNames.EnvironmentsList) || "[]"
    );
    const envIndex = savedEnvList.findIndex(e => e === env.name);

    if (envIndex === -1) {
      savedEnvList.unshift(env.name);
    }
    localStorage.setItem(
      LSNames.EnvironmentsList,
      JSON.stringify(savedEnvList)
    );
  }

  getEnvironment(): IEnvironment | null {
    const env = localStorage.getItem(LSNames.Environment);

    if (env) {
      return JSON.parse(env);
    }

    return null;
  }

  checkEnvironment() {
    if (this.isBrowser()) return;
    const environment = this.getEnvironment();

    if (!environment) {
      this.setEnvironment(
        this.findEnvironmentPreset(
          environmentPreset ? environmentPreset : "prod"
        )
      );
    } else {
      if (environment.name !== "custom") {
        const validEnvironment = this.findEnvironmentPreset(environment.name);

        if (
          !validEnvironment ||
          environment.baseApiEndpoint !== validEnvironment.baseApiEndpoint ||
          environment.meetUrl !== validEnvironment.meetUrl ||
          environment.webUrl !== validEnvironment.webUrl ||
          environment.centrifugeEndpoint !== validEnvironment.centrifugeEndpoint
        ) {
          this.setEnvironment(validEnvironment);
          location.reload();
        }
      }
    }
  }

  getEnvironmentFromLocation(): IEnvironment | null {
    return environments.find(e => e.webUrl.includes(location.hostname)) || null;
  }

  pause(length: number): Promise<void> {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve();
      }, length);
    });
  }

  logout(force: boolean = false, callback?: Function) {
    const store = require("@/store").default;
    const logoutCallback = async (force: boolean = false) => {
      const localStoragePreserveNames = [
        LSNames.Environment,
        LSNames.ServiceTutorialRead,
        LSNames.MainTutorialRead,
        LSNames.CustomServiceList,
        LSNames.SeenOnboarding,
        LSNames.EnvironmentsList,
        LSNames.Dev,
        LSNames.DarkTheme,
        LSNames.CallSettings,
        LSNames.RingtoneEnabled
      ];
      const localStoragePreserve: Record<string, string | null> = {};

      localStoragePreserveNames.forEach(localStoragePreserveName => {
        localStoragePreserve[localStoragePreserveName] = localStorage.getItem(
          localStoragePreserveName
        );
      });
      if (localStorage.getItem(LSNames.AT)) {
        if (store.state.waitingCallWindow) {
          try {
            await Infra.meet.EndMeet(store.state.waitingCallWindow.room);
          } catch {}
        }
        try {
          await Infra.auth.Logout();
        } catch {}
      }
      localStorage.clear();
      if (!this.isBrowser()) {
        Electron().ipcRenderer.send("logout", !force);
        Electron().ipcRenderer.send("set-badge-count", 0);
      }
      void store.dispatch("streamStore/disconnect");
      await this.pause(200);
      void store.dispatch("clearAllData");
      for (const key in localStoragePreserve) {
        const value = localStoragePreserve[key];

        if (value) {
          localStorage.setItem(key, value);
        }
      }
      if (callback) {
        callback();
      }
      const router = require("@/router").default;

      await router.push({
        name: "auth",
        query: {
          logout: force ? "auto" : "manual"
        }
      });
    };

    if (force) {
      logoutCallback(true);

      return;
    }
    store.commit("showPopup", {
      title: "Выход",
      text: "Вы действительно уверены, что хотите выйти из аккаунта TN Life?",
      buttonsLayout: "horizontal",
      buttons: [
        {
          text: "Выйти",
          callback: index => {
            store.commit("closePopup", index);
            logoutCallback();
          },
          type: "outline"
        },
        {
          text: "Отмена",
          type: "primary",
          callback: index => {
            store.commit("closePopup", index);
          }
        }
      ]
    } as IMutationPopup);
  }

  isBrowser(): boolean {
    return typeof process !== "object" || process.title === "browser";
  }

  isMacOS(): boolean {
    return new UAParser(navigator.userAgent).getOS().name === "Mac OS";
  }

  formatLastUpdatedAt(date: Date): string {
    return formatLastUpdatedAt(date);
  }

  getDateSplitter(date: Date): string {
    const today: Date = new Date();
    const todayMidnight = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate()
    );

    if (
      todayMidnight.getDate() === date.getDate() &&
      todayMidnight.getFullYear() === date.getFullYear() &&
      todayMidnight.getMonth() === date.getMonth()
    ) {
      return "Сегодня";
    } else if (todayMidnight.getTime() - date.getTime() < threeDays / 3) {
      return "Вчера";
    } else if (todayMidnight.getFullYear() === date.getFullYear()) {
      return format(date, "d MMMM", { locale: ru });
    }

    return format(date, "d MMMM yyyy", { locale: ru });
  }

  async getBaseFromFile(file: File): Promise<string | null> {
    return new Promise((resolve, reject) => {
      try {
        const reader = new FileReader();

        reader.onload = e => {
          resolve(
            e.target && typeof e.target.result === "string"
              ? e.target.result
              : null
          );
        };

        reader.readAsDataURL(file);
      } catch (error) {
        reject(error);
      }
    });
  }

  getHTMLFromMessage(message: IMessage): string {
    const entities = message.entities.sort((a, b) => {
      if (a.start > b.start) {
        return 1;
      } else if (a.start < b.start) {
        return -1;
      } else {
        if (a.length > b.length) {
          return -1;
        } else if (a.length < b.length) {
          return 1;
        }
      }

      return 0;
    });
    let messageBody = "";

    const handleMessage = (character: string, index: number) => {
      const startAtIndex = entities
        .filter(e => e.start === index)
        .sort((a, b) => {
          if (a.length > b.length) {
            return -1;
          } else if (a.length <= b.length) {
            return 1;
          }

          return 0;
        });
      const endAtIndex = entities
        .filter(e => e.start + e.length === index)
        .sort((a, b) => {
          if (a.start >= b.start) {
            return -1;
          } else if (a.start < b.start) {
            return 1;
          }

          return 0;
        });

      endAtIndex.forEach(ent => {
        if (ent.type === MessageEntity.Type.URL) {
          messageBody += "</a>";
        } else if (ent.type === MessageEntity.Type.BOLD) {
          messageBody += "</b>";
        } else if (ent.type === MessageEntity.Type.ITALIC) {
          messageBody += "</i>";
        } else if (ent.type === MessageEntity.Type.MENTION) {
          messageBody += "</a>";
        } else if (ent.type === MessageEntity.Type.STRIKETHROUGH) {
          messageBody += "</s>";
        } else if (ent.type === MessageEntity.Type.UNDERLINE) {
          messageBody += "</u>";
        }
      });
      startAtIndex.forEach(ent => {
        if (ent.type === MessageEntity.Type.URL) {
          messageBody += `<a href="${ent.value}">`;
        } else if (ent.type === MessageEntity.Type.BOLD) {
          messageBody += "<b>";
        } else if (ent.type === MessageEntity.Type.ITALIC) {
          messageBody += "<i>";
        } else if (ent.type === MessageEntity.Type.MENTION) {
          messageBody += `<a href="mention:${ent.value}">`;
        } else if (ent.type === MessageEntity.Type.STRIKETHROUGH) {
          messageBody += "<s>";
        } else if (ent.type === MessageEntity.Type.UNDERLINE) {
          messageBody += "<u>";
        }
      });
      if (character === "\n") {
        messageBody += "<br/>";
      } else {
        messageBody += character;
      }
    };

    message.body.split("").forEach((character, index) => {
      handleMessage(character, index);
    });
    handleMessage("", message.body.split("").length);

    return messageBody;
  }

  saveSelection(): Range[] {
    const sel = window.getSelection();

    if (sel?.getRangeAt && sel.rangeCount) {
      const ranges: Range[] = [];

      for (let i = 0, len = sel.rangeCount; i < len; ++i) {
        const range = sel.getRangeAt(i);

        if (range) ranges.push(range);
      }

      return ranges;
    }

    return [];
  }

  restoreSelection(ranges: Range[]): void {
    const sel = window.getSelection();

    if (sel) {
      sel.removeAllRanges();
      for (let i = 0, len = ranges.length; i < len; ++i) {
        sel.addRange(ranges[i]);
      }
    }
  }

  appendLinks(text: string, plainTextMode: boolean = false): string {
    try {
      const messageLinkRegex = RegExp(messageLinkRegexString);

      if (plainTextMode) {
        text = text.replaceAll(/\n/gm, "<br>");
      }

      text = text.replace(/<br\s?\/?>/gm, " <br> ");
      text = text.replace(/<b>/gm, " <b> ");
      text = text.replace(/<\/b>/gm, " </b> ");
      text = text.replace(/<i>/gm, " <i> ");
      text = text.replace(/<\/i>/gm, " </i> ");
      text = " " + text + " ";

      while (messageLinkRegex.test(text)) {
        const match = text.match(messageLinkRegex);

        if (match) {
          let matchString = match[0];

          if (!/^https?:\/\//.test(matchString)) {
            matchString = "https://" + matchString;
          }
          const linkTag = `<a href="${matchString}">${match[0]}</a>`;

          text = text.replace(messageLinkRegex, linkTag);
        }
      }

      text = text.replaceAll(/ <br> /gm, "<br>");
      text = text.replaceAll(/ <b> /gm, "<b>");
      text = text.replaceAll(/ <\/b> /gm, "</b>");
      text = text.replaceAll(/ <i> /gm, "<i>");
      text = text.replaceAll(/ <\/i> /gm, "</i>");

      text = text.trim();
    } catch {}

    return text;
  }

  getMessageEntityFromHTML(html: string): {
    content: string;
    entities: IMessageEntity[];
  } {
    let content = "";
    let bodyIndex = 0;
    let startTagBody = false;
    let endTagBody = false;
    const entities: IMessageEntity[] = [];
    const tempEntities: IMessageEntity[] = [];
    let settingLink = false;
    let strikeThroughLink = false;
    let settingLinkValue = false;
    let linkValue = "";

    html = html.trim();
    html = html.replaceAll("&amp;", "&");
    html = html.replaceAll("&nbsp;", " ");

    html = this.appendLinks(html);

    html = html.replaceAll(/ {2,}/g, " ");

    html.split("").forEach((character, index) => {
      const nextCharacter = html[index + 1];

      if (character === "<") {
        if (nextCharacter && nextCharacter === "/") {
          endTagBody = true;
        } else {
          startTagBody = true;
        }
      } else if (character === ">") {
        if (endTagBody) {
          endTagBody = false;
          const lastItem = tempEntities.pop();

          if (lastItem) {
            if (lastItem.type === MessageEntity.Type.URL) {
              lastItem.value = linkValue;
              linkValue = "";
              settingLink = false;
            } else if (lastItem.type === MessageEntity.Type.STRIKETHROUGH) {
              strikeThroughLink = false;
            }
            lastItem.length = bodyIndex - lastItem.start;
            entities.push(lastItem);
          }
        } else if (startTagBody) {
          startTagBody = false;
        }
      } else if (character === "i") {
        if (settingLinkValue) {
          linkValue += character;
        } else if (!strikeThroughLink) {
          if (startTagBody) {
            tempEntities.push({
              type: MessageEntity.Type.ITALIC,
              length: 0,
              start: bodyIndex
            });
          } else if (!endTagBody) {
            content += character;
            bodyIndex++;
          }
        }
      } else if (character === "u") {
        if (settingLinkValue) {
          linkValue += character;
        } else {
          if (startTagBody) {
            tempEntities.push({
              type: MessageEntity.Type.UNDERLINE,
              length: 0,
              start: bodyIndex
            });
          } else if (!endTagBody) {
            content += character;
            bodyIndex++;
          }
        }
      } else if (character === "s") {
        if (settingLinkValue) {
          linkValue += character;
        } else {
          if (startTagBody) {
            strikeThroughLink = true;
            tempEntities.push({
              type: MessageEntity.Type.STRIKETHROUGH,
              length: 0,
              start: bodyIndex
            });
          } else if (!endTagBody) {
            content += character;
            bodyIndex++;
          }
        }
      } else if (character === "a") {
        if (settingLinkValue) {
          linkValue += character;
        } else {
          if (startTagBody) {
            tempEntities.push({
              type: MessageEntity.Type.URL,
              length: 0,
              start: bodyIndex
            });
            settingLink = true;
          } else if (!endTagBody) {
            content += character;
            bodyIndex++;
          }
        }
      } else if (character === "b") {
        if (settingLinkValue) {
          linkValue += character;
        } else {
          if (startTagBody) {
            if (nextCharacter && nextCharacter === "r") {
              content += "\n";
              bodyIndex++;
            } else {
              tempEntities.push({
                type: MessageEntity.Type.BOLD,
                length: 0,
                start: bodyIndex
              });
            }
          } else if (!endTagBody) {
            content += character;
            bodyIndex++;
          }
        }
      } else if (character === "\"") {
        if (startTagBody && settingLink) {
          settingLinkValue = !settingLinkValue;
        } else {
          content += character;
          bodyIndex++;
        }
      } else {
        if (!(endTagBody || startTagBody)) {
          bodyIndex++;
          content += character;
        }
        if (settingLinkValue) {
          linkValue += character;
        }
      }
    });

    return {
      content,
      entities
    };
  }

  getImagesGridTemplate(imagesCount = 0): string {
    if (imagesCount && imagesCount <= 10) {
      const rowsCount: 1 | 2 | 3 | 4 =
        imagesCount < 2 ? 1 : imagesCount < 6 ? 2 : imagesCount < 10 ? 3 : 4;
      let templateString = "\"";
      let elementCounter = 0;

      for (let r = 0; r < rowsCount; r++) {
        let columnsCount: 1 | 2 | 3 | 4 = 1;

        if (r === 0) {
          columnsCount =
            imagesCount < 3 ? 1 : imagesCount < 5 ? 2 : imagesCount < 9 ? 3 : 4;
        } else if (r === 1) {
          columnsCount = imagesCount < 4 ? 1 : imagesCount < 7 ? 2 : 3;
        } else if (r === 2) {
          columnsCount = imagesCount < 8 ? 1 : 2;
        } else if (r === 3) {
          columnsCount = 1;
        }
        for (let c = 0; c < columnsCount; c++) {
          const character = String.fromCharCode(97 + elementCounter++);

          templateString += (character + " ").repeat(12 / columnsCount);
        }
        templateString = templateString.trim() + "\"\n\"";
      }

      return templateString.replace(/"$/, "").replace(/\n$/, "");
    }

    return "\"a a a b b b c c c d d d\"\n\"e e e e f f f f g g g g\"\n\"h h h h h h i i i i i i\"\n\"j j j j j j j j j j j j\"";
  }

  getImagesGridTemplateRows(imagesCount = 0): string {
    if (imagesCount === 1) {
      return "var(--xl)";
    } else if (imagesCount === 2) {
      return "var(--l) var(--l)";
    } else if (imagesCount === 3) {
      return "var(--m) var(--l)";
    } else if ([4, 5].includes(imagesCount)) {
      return "var(--m) var(--m)";
    } else if ([6, 7].includes(imagesCount)) {
      return "var(--m) var(--m) var(--l)";
    } else if ([8, 9].includes(imagesCount)) {
      return "var(--m) var(--m) var(--m)";
    } else if (imagesCount === 10) {
      return "var(--m) var(--m) var(--m) var(--l)";
    }

    return "var(--m) var(--m) var(--m) var(--l)";
  }

  prettifyFileSize(size: number): string {
    if (size < 1024) {
      return size + " байт";
    } else if (size >= Math.pow(10, 3) && size < Math.pow(10, 6)) {
      return Math.round(size / Math.pow(10, 3)) + " Кб";
    } else if (size >= Math.pow(10, 6) && size < Math.pow(10, 9)) {
      return Math.round(size / Math.pow(10, 6)) + " Mб";
    } else {
      return Math.round(size / Math.pow(10, 9)) + " Гб";
    }
  }

  getMessageBody(message: IMessage, notification?: boolean): string {
    return getMessageBody(message, notification);
  }

  getRouteFromAppLink(link: string): {
    payload: string;
    appId: string;
    type: "user" | "app" | "meets" | "meet" | "other" | "chat" | "contacts";
  } {
    const payloadSeparate = link.split("?payload=") || [];
    let payload = payloadSeparate[1];
    const appId = (payloadSeparate[0].match(appIdFragmentRegex) || [])[0] || "";

    if (payload) {
      payload = decodeURI(payload);
    }
    const type = link.includes("/app/")
      ? "app"
      : link.includes("/user/")
        ? "user"
        : link.includes("/meet/")
          ? "meet"
          : link.endsWith("/meets")
            ? "meets"
            : link.includes("/chat")
              ? "chat"
              : link.includes("/contacts")
                ? "contacts"
                : "other";

    return {
      appId,
      payload,
      type
    };
  }

  async notificationHandler(
    stream: Stream,
    message: IMessage,
    contactList: IUser[]
  ) {
    if (window.isEmbedded) return;
    const title: string = stream.title,
      body: string = this.getMessageBody(message, true);
    let subtitle: string | undefined;
    const userFromContacts = contactList.find(u => u.id === message.author?.id);

    if (stream.type === Type.GROUP) {
      subtitle = userFromContacts
        ? userFromContacts.renderName
        : message.author?.renderName;
    }
    let image: string | undefined;
    const imageData = message.images[0];
    const imageId = imageData?.thumb?.id;

    if (imageData && imageId) {
      try {
        const request = await Infra.file.Download(
          imageId,
          imageData.thumb?.type || ""
        );

        image = (await request.promise).base;
      } catch {}
    }
    this.showPush(
      title,
      body,
      "message",
      subtitle || "",
      undefined,
      "desktop",
      {
        streamId: stream.id
      },
      image
    );
  }

  showPush(
    title: string,
    body: string,
    type: string,
    subtitle?: string,
    webClickHandler?: () => void,
    mode: "desktop" | "web" | "all" = "all",
    payload?: any,
    image?: string,
    silent?: boolean
  ) {
    if (!this.isBrowser() && mode !== "web" && !location.pathname.startsWith("/meet/")) {
      Electron().ipcRenderer.send("notification", {
        title,
        body,
        subtitle,
        type,
        payload,
        image,
        silent
      });
    } else if ("Notification" in window && mode !== "desktop") {
      Notification.requestPermission().then(response => {
        if (response !== "denied") {
          const notification = new Notification(title, { body });

          notification.addEventListener("click", () => {
            if (webClickHandler) webClickHandler();
            notification.close();
          });
        }
      });
    }
  }

  callNotification(
    subject: string,
    initiator?: string,
    isGroup?: boolean
  ): void {
    this.showPush(
      subject,
      initiator && isGroup
        ? "Начинает звонок " + initiator
        : "Входящий звонок...",
      "call",
      undefined,
      undefined,
      "desktop",
      "call",
      undefined,
      true
    );
  }

  updateBadgeCount(count: number): void {
    if (!this.isBrowser()) {
      Electron().ipcRenderer.send("set-badge-count", count);
    } else {
      const titleArray = document.title.split("TN Life");

      if (titleArray.length === 2) {
        if (count) {
          if (titleArray[0]) {
            document.title = `(${count}) TN Life ${titleArray[1]}`;
          } else {
            document.title = `(${count}) ${document.title}`;
          }
        } else {
          document.title = document.title.replace(/\(\d+\)\s/, "");
        }
      }
    }
  }

  declOfNum(n: number, text_forms: string[]): string {
    if (isNaN(n) || text_forms.length < 3) {
      return "";
    }
    n = Math.abs(n) % 100;
    const n1 = n % 10;

    if (n > 10 && n < 20) {
      return text_forms[2];
    }
    if (n1 > 1 && n1 < 5) {
      return text_forms[1];
    }
    if (n1 == 1) {
      return text_forms[0];
    }

    return text_forms[2];
  }

  baseToFile(base: string, filename: string): File {
    const arr = base.split(",");
    let mime: RegExpMatchArray | null = null;
    let baseString: string;

    if (arr.length > 1) {
      mime = arr[0].match(/:(.*?);/);
      baseString = atob(arr[1]);
    } else {
      baseString = atob(arr[0]);
    }
    let n = baseString.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = baseString.charCodeAt(n);
    }

    return new File([u8arr], filename, {
      type: mime?.[0] ? mime[0].replace(":", "").replace(";", "") : "image/png"
    });
  }

  getBase64(file: File): Promise<string> {
    const reader = new FileReader();

    reader.readAsDataURL(file);

    return new Promise((resolve, reject) => {
      reader.onload = function () {
        if (typeof reader.result === "string") {
          resolve(reader.result);
        } else if (reader.result) {
          const decoder = new TextDecoder("utf-8");

          resolve(decoder.decode(reader.result));
        }
      };
      reader.onerror = function (error) {
        reject(error);
      };
    });
  }

  setPageTitle(title: string, rewrite: boolean = false): void {
    if (this.isBrowser()) {
      const count = document.title.split("TN Life")[0];

      document.title = count + (rewrite ? title : "TN Life - " + title);
    }
  }

  relativeBirthDate(target: Date, now?: Date): string {
    if (!now) now = new Date();
    const date = new Date(target.getTime());

    date.setFullYear(now.getFullYear());
    date.setMilliseconds(0);
    date.setSeconds(0);
    date.setMinutes(0);
    date.setHours(0);
    now.setMilliseconds(0);
    now.setSeconds(0);
    now.setMinutes(0);
    now.setHours(0);
    if (date.getTime() < now.getTime()) {
      date.setFullYear(now.getFullYear() + 1);
    }
    const diffTime = (date.getTime() - now.getTime()) / 1000 / 60 / 60 / 24;

    if (diffTime < 1) {
      return "Сегодня";
    } else if (diffTime === 1) {
      return "Завтра";
    } else if (diffTime <= 5) {
      return `До дня рождения ${diffTime} ${this.declOfNum(diffTime, [
        "день",
        "дня",
        "дней"
      ])}`;
    }

    return "День рождения";
  }

  getDimensionsOfImage(
    file: File,
    noError: boolean = false
  ): Promise<{ width: number; height: number }> {
    return new Promise((resolve, reject) => {
      const fr = new FileReader();

      fr.onload = function () {
        const img = new Image();

        img.onload = function () {
          resolve({
            width: img.width,
            height: img.height
          });
        };

        img.onerror = function () {
          if (noError) {
            resolve({ width: 0, height: 0 });
          } else {
            reject("Ошибка обработки изображения");
          }
        };

        img.src = fr.result as string;
      };
      fr.onerror = function () {
        if (noError) {
          resolve({ width: 0, height: 0 });
        } else {
          reject("Ошибка обработки файла из файловой системы");
        }
      };

      fr.readAsDataURL(file);
    });
  }

  log(message: any[] | string) {
    if (typeof message === "string") {
      console.log(message); // eslint-disable-line no-console
    } else {
      console.log(...message); // eslint-disable-line no-console
    }
  }

  groupLog(title: any[], data: any) {
    if (localStorage.getItem(LSNames.Dev) === "1") {
      console.groupCollapsed(...title); // eslint-disable-line no-console
      console.table(data); // eslint-disable-line no-console
      console.groupEnd(); // eslint-disable-line no-console
    }
  }

  validateSemver(version: string, semverPattern: string): boolean {
    return semver.satisfies(version, semverPattern);
  }

  compareVersions(v1: string, v2: string): -1 | 0 | 1 {
    return semver.compare(v1, v2);
  }

  checkVersion(setting: GetSettingsResponse.AsObject): boolean {
    const store = require("@/store").default;

    if (!this.isBrowser()) {
      const delayNewVersion: {
        date?: number;
        version?: string;
      } = JSON.parse(localStorage.getItem(LSNames.DelayNewVersion) || "{}");
      const desktopAppUrl = navigator.userAgent.match(
          /(Mac OS|MacPPC|MacIntel|Mac_PowerPC|Macintosh|Mac OS X)/
        )
          ? setting.desktopmacosappurl
          : navigator.userAgent.match(/(Windows)/)
            ? setting.desktopwindowsappurl
            : "",
        appVersion = VUE_APP_VERSION,
        ignoreNewVersion: boolean =
          delayNewVersion.version === setting.currentdesktopversion &&
          (!delayNewVersion.date || delayNewVersion.date > Date.now());

      if (
        setting.mindesktopversion &&
        setting.currentdesktopversion &&
        desktopAppUrl &&
        !this.validateSemver(appVersion, setting.mindesktopversion)
      ) {
        store.commit("setUpdateStatus", {
          actual: setting.currentdesktopversion,
          appUrl: desktopAppUrl,
          force: true
        });

        return false;
      } else if (
        !ignoreNewVersion &&
        setting.currentdesktopversion &&
        desktopAppUrl &&
        this.compareVersions(appVersion, setting.currentdesktopversion) === -1
      ) {
        localStorage.removeItem(LSNames.DelayNewVersion);
        store.commit("setUpdateStatus", {
          actual: setting.currentdesktopversion,
          appUrl: desktopAppUrl,
          force: false
        });

        return false;
      }
    }
    store.commit("setUpdateStatus", null);

    return true;
  }

  async updateAndCheckVersion() {
    const config = await Infra.setting.Config();
    const store = require("@/store").default;

    store.commit("settingsStore/setConfig", config);
    this.checkVersion(config);
  }

  serviceSort(service1: IApp | ISite, service2: IApp | ISite): -1 | 0 | 1 {
    if (service1.priority < service2.priority) {
      return 1;
    } else if (service1.priority > service2.priority) {
      return -1;
    } else {
      if (service1.title.toLowerCase() > service2.title.toLowerCase()) {
        return 1;
      } else if (service1.title.toLowerCase() < service2.title.toLowerCase()) {
        return -1;
      } else {
        if (service1.createDate > service2.createDate) {
          return 1;
        } else if (service1.createDate < service2.createDate) {
          return -1;
        }
      }
    }

    return 0;
  }

  updateUser(oldUser: IUser, newUser: IUser): IUser {
    const personKeys = [
      "funcLeader",
      "admLeader",
      "subordinates",
      "coworkers",
      "phoneList",
      "birthdate",
      "absence",
      "location",
      "avatar"
    ];
    const updatedObject: IUser = { ...oldUser };

    for (const key in oldUser) {
      if (personKeys.includes(key)) continue;
      if (newUser[key] && oldUser[key]) {
        updatedObject[key] =
          typeof newUser[key] === "object"
            ? Array.isArray(newUser[key])
              ? [...newUser[key]]
              : newUser[key] instanceof Date
                ? new Date(newUser[key])
                : { ...newUser[key] }
            : newUser[key];
      }
    }
    for (const key in newUser) {
      if (personKeys.includes(key)) {
        updatedObject[key] = newUser[key];
      } else if (newUser[key] && !oldUser[key]) {
        updatedObject[key] =
          typeof newUser[key] === "object"
            ? Array.isArray(newUser[key])
              ? [...newUser[key]]
              : newUser[key] instanceof Date
                ? new Date(newUser[key])
                : { ...newUser[key] }
            : newUser[key];
      }
    }

    return updatedObject;
  }

  isOnMeetPage(): string {
    let path: string;

    if (this.isBrowser()) {
      path = location.pathname.replace(/^\//, "");
    } else {
      path = location.hash.replace(/^#\//, "");
    }

    return /^meet\/.+/.test(path) ? path.replace(/^meet\//, "") : "";
  }

  initials(name: string = ""): string {
    const nameArray = name.split(" ");

    return nameArray
      .map((nameItem, index) => (index ? nameItem.toUpperCase()[0] : nameItem))
      .reduce<string>(
        (prev, curr, currIndex) => prev + curr + (currIndex ? "." : " "),
        ""
      )
      .trim();
  }

  formatPhoneNumber(phone: string): string {
    phone = phone.replaceAll(/\D/g, "");
    if (phone.startsWith("8")) {
      phone = phone.replace("8", "+7");
    } else if (phone.startsWith("7")) {
      phone = phone.replace("7", "+7");
    }
    const phoneArray = phone.split("");

    phone = phoneArray.reduce<string>(
      (previousValue, currentValue, currentIndex) => {
        if ([2, 5, 8, 10].includes(currentIndex)) {
          previousValue += " ";
        }
        previousValue += currentValue;

        return previousValue;
      },
      ""
    );

    return phone;
  }

  getFileExtension(fileName: string): string {
    const match = fileName.toLowerCase().match(/\.\w+$/);

    return match?.length ? match[0].replace(".", "") : "";
  }

  pickFileToastText(
    pickFileLength: number,
    filesLimit: number,
    extraFilesCount: number,
    images: boolean
  ): string {
    if (!images) {
      return "Можно выбрать только один файл";
    }
    if (pickFileLength === extraFilesCount) {
      return "Больше изображений выбрать нельзя";
    }
    const pickFilesText =
      pickFileLength > 1 ? `Из ${pickFileLength} изображений ` : "";
    const extraFilesText = `${extraFilesCount} ${this.declOfNum(
      extraFilesCount,
      pickFileLength > 1
        ? ["не было выбрано", "не были выбраны", "не были выбраны"]
        : [
            "изображение не было выбрано",
            "изображения не были выбраны",
            "изображений не были выбраны"
          ]
    )}`;
    const filesLimitText = `, так как ${
      filesLimit
        ? filesLimit > 1
          ? "можно было выбрать " + filesLimit
          : "можно было выбрать одно"
        : "больше выбрать нельзя"
    }`;

    return (pickFilesText + extraFilesText + filesLimitText).trim();
  }

  getNewMeetDate(next: boolean = false): Date {
    const now = new Date();

    const minutes = now.getMinutes();

    if (minutes > 55) {
      now.setHours(now.getHours() + 1);
      now.setMinutes(0);
    } else {
      now.setMinutes(now.getMinutes() - (minutes % 5) + 5);
    }
    if (next) {
      now.setMinutes(now.getMinutes() + 30);
    }
    now.setSeconds(0);
    now.setMilliseconds(0);

    return now;
  }

  randomIntegerFromRange(min: number, max: number): number {
    min = Math.ceil(min);
    max = Math.floor(max);

    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  getTimezone(list: ITimezone[]): ITimezone | undefined {
    const localTimezoneName = Intl.DateTimeFormat()
      .resolvedOptions()
      .timeZone.replace(/^.*\//, "");
    let timezone = list.find(tz => localTimezoneName.includes(tz.enLocale));

    if (!timezone) {
      const localTimezoneDiff = -(new Date().getTimezoneOffset() / 60);

      timezone = list.find(tz => localTimezoneDiff === tz.diff);
    }

    return timezone;
  }

  getPeriodicityText(periodicity: IPeriodicity): string {
    let weekDaysText: string;

    if (periodicity.value.length === 7) {
      weekDaysText = "ежедневно";
    } else if (
      periodicity.value.length === 5 &&
      periodicity.value.every(wdi => wdi >= 0 && wdi <= 4)
    ) {
      weekDaysText = "по будням";
    } else {
      weekDaysText =
        "по следующим дням: " +
        periodicity.value.sort().reduce<string>((value, current, index) => {
          if (index && index === periodicity.value.length - 1) {
            value += " и " + weekDays[current];
          } else {
            value += (index ? ", " : "") + weekDays[current];
          }

          return value;
        }, "");
    }

    return `С ${format(periodicity.start, "d.MM.yyyy")} встреча будет проходить каждую ${
      periodicity.interval > 1 ? periodicity.interval : ""
    } неделю ${weekDaysText} `;
  }
  toggleDarkTheme() {
    if (localStorage.getItem(LSNames.DarkTheme) === "1") {
      localStorage.removeItem(LSNames.DarkTheme);
    } else {
      localStorage.setItem(LSNames.DarkTheme, "1");
    }
    this.applyDarkTheme();
  }
  applyDarkTheme() {
    const darkThemeEnabled = localStorage.getItem(LSNames.DarkTheme) === "1";

    if (!this.isBrowser()) {
      Electron().ipcRenderer.send("toggle-dark-mode", darkThemeEnabled);
    }
    const themeColorMeta = document.querySelector("meta[name=\"theme-color\"]");

    if (darkThemeEnabled) {
      themeColorMeta?.setAttribute("content", "#1e2228");
      document.body.classList.add("dark-theme");
    } else {
      themeColorMeta?.setAttribute("content", "#ffffff");
      document.body.classList.remove("dark-theme");
    }
  }
  toggleBlurMode() {
    if (localStorage.getItem(LSNames.BlurMode) === "1") {
      localStorage.removeItem(LSNames.BlurMode);
    } else {
      localStorage.setItem(LSNames.BlurMode, "1");
    }
    this.applyBlurMode();
  }
  applyBlurMode() {
    const blurModeEnabled = localStorage.getItem(LSNames.BlurMode) === "1";

    if (blurModeEnabled) {
      document.body.classList.add("blur-mode");
    } else {
      document.body.classList.remove("blur-mode");
    }
  }
}

export class StreamService {
  mainService = new MainService();

  getIconUuid(
    type?: Type,
    imageId?: string,
    interlocutorId?: string,
    users?: IUser[]
  ): string {
    if (type !== Type.DIRECT) {
      return imageId || nullUuid;
    }

    return (
      (users || []).find(user => user.id === interlocutorId)?.avatar?.id ||
      nullUuid
    );
  }

  getIconUrl(
    type?: Type,
    imageUrl?: string,
    interlocutorId?: string,
    users?: IUser[]
  ): string | undefined {
    if (type !== Type.DIRECT) {
      return imageUrl;
    }

    return (users || []).find(user => user.id === interlocutorId)?.avatar?.url;
  }

  getChatName(
    type: Type,
    isPersonalChat: boolean,
    users: IUser[],
    name: string,
    interlocutorId: string | undefined,
    contactList: IUser[]
  ): string {
    if (type === Type.DIRECT) {
      if (isPersonalChat) {
        return "Избранное";
      }
      const user = users.find(userItem => userItem.id === interlocutorId && interlocutorId !== nullUuid);

      if (user && type === Type.DIRECT) {
        let newName = user.renderName;
        const contactUser = contactList.find(contact => contact.id === user.id);

        if (contactUser) {
          newName = contactUser.renderName;
        }

        return newName;
      }
    }

    return name.trim();
  }

  getLastMessageAuthor(
    type: Type,
    interlocutorId: string | undefined,
    lastMessage: IMessage | undefined,
    users: IUser[],
    user: IUser | null,
    contactList: IUser[]
  ): string {
    if (lastMessage?.author) {
      if (lastMessage?.type === Message.Type.SERVICE) {
        return "";
      }
      if (lastMessage.guest) {
        return lastMessage.author.renderName;
      }
      const authorId = lastMessage.author.id;

      if (authorId) {
        const messageAuthor = users.find(user => user.id === authorId);

        if (messageAuthor && user) {
          if (user.id === interlocutorId) {
            return "";
          }
          if (user.id === messageAuthor.id) {
            return "Вы";
          }
          if (type !== Type.GROUP) {
            return "";
          }
          let name = messageAuthor.renderName;
          const contactUser = contactList.find(
            contact => contact.id === messageAuthor.id
          );

          if (contactUser) {
            name = contactUser.renderName;
          }

          return name;
        }
      }
    }

    return "";
  }

  getChatTime(createDate: Date, lastMessageDate: Date | undefined): string {
    if (lastMessageDate) {
      return this.mainService.formatLastUpdatedAt(lastMessageDate);
    }

    return this.mainService.formatLastUpdatedAt(createDate);
  }

  getChatBody(lastMessage?: IMessage): string {
    if (lastMessage) {
      return this.mainService.getMessageBody(lastMessage);
    }

    return "";
  }

  isMessageRead(messageId: string, lastReadMessageId?: string): boolean {
    if (!lastReadMessageId) {
      return false;
    }

    return !!(lastReadMessageId && messageId <= lastReadMessageId);
  }

  timestampAdapter(timestamp: { seconds: number } | undefined): Date {
    return new Date((timestamp?.seconds || 0) * 1000);
  }

  messageParser(messageBinary: string | Uint8Array): Message.AsObject {
    if (typeof messageBinary === "string") {
      return Message.deserializeBinary(
        Buffer.from(messageBinary, "base64")
      ).toObject();
    }

    return Message.deserializeBinary(messageBinary).toObject();
  }

  streamsAdapter(
    stream: OuterStream.AsObject,
    user: IUser,
    users: IUser[],
    contactList: IUser[],
    activeMeetRoomId?: string,
    oncomingMeeting?: {
      stream_id: string;
      meet_id: string;
      date: string;
      subject: string;
    },
    isNew?: boolean
  ): Stream {
    const isCanWrite =
      (stream.type === Type.CHANNEL &&
        stream.meta &&
        stream.meta.owner === user?.id) ||
      stream.type !== Type.CHANNEL;
    const isCanLeave =
      stream.type === Type.CHANNEL
        ? stream.meta?.owner === user?.id &&
          stream.systemtype !== SystemType.SUPER_CHANNEL
        : stream.type === Type.DIRECT
          ? stream?.meta?.interlocutor !== user?.id ||
            stream?.meta?.owner === nullUuid
          : !stream?.meet;

    return new Stream({
      id: stream.id || nullUuid,
      type: stream.type || 0,
      createDate: isNew ? new Date() : this.timestampAdapter(stream.createdat),
      isPinned: stream.meta?.pinned,
      interlocutorId: stream.meta?.interlocutor,
      lastReadMessageId: nullUuid,
      isCanWrite,
      isCanLeave,
      messages: [],
      lastMessageDateString: this.getChatTime(
        this.timestampAdapter(stream.createdat),
        undefined
      ),
      lastMessageDate: this.timestampAdapter(stream.createdat),
      title: this.getChatName(
        stream.type,
        stream.issavedmessages,
        users,
        stream.title || "Безымянный",
        stream.meta?.interlocutor,
        contactList
      ),
      lastMessageBody: "",
      lastMessageAuthorName: "",
      lastMessageId: "",
      isPersonalChat: stream.issavedmessages,
      iconUuid: this.getIconUuid(
        stream.type,
        stream?.logo?.original,
        stream.meta?.interlocutor,
        users
      ),
      iconUrl: this.getIconUrl(
        stream.type,
        stream.logo?.photolargeurl,
        stream.meta?.interlocutor,
        users
      ),
      private: stream.visible === Visible.PRIVATE || false,
      description: stream.description,
      canEdit: stream.meta?.owner === user?.id,
      ownerId: stream.meta?.owner || nullUuid,
      isSuperChannel: stream.systemtype === SystemType.SUPER_CHANNEL,
      callData: activeMeetRoomId
        ? {
            roomId: activeMeetRoomId
          }
        : null,
      oncomingMeeting: oncomingMeeting?.meet_id
        ? {
            meetId: oncomingMeeting.meet_id,
            // todo: сдвиг на локальное время?
            date: new Date(oncomingMeeting.date),
            subject: oncomingMeeting.subject
          }
        : null,
      meetData: stream.meet ? stream.meet : null,
      isArchived: !!stream.meta?.archived
    });
  }

  notificationStreamMetaAdapterRaw(
    meta: Centrifuge.IStreamMeta
  ): OuterStream.Meta.AsObject {
    return {
      ...meta,
      updatedat: {
        seconds: new Date(meta.updated_at || 0).getTime() / 1000,
        nanos: 0
      },
      userscount: meta.users_count
    };
  }

  notificationStreamAdapterRaw(
    stream: Centrifuge.IStream
  ): OuterStream.AsObject {
    return {
      ...stream,
      description: stream.description || "",
      cid: "",
      logo: stream.logo
        ? this.notificationAvatarAdapterRaw(stream.logo)
        : undefined,
      createdat: {
        seconds: new Date(stream.created_at || 0).getTime() / 1000,
        nanos: 0
      },
      issavedmessages: !!stream.is_saved_messages,
      meta: stream.meta
        ? this.notificationStreamMetaAdapterRaw(stream.meta)
        : undefined,
      systemtype: stream.system_type,
      filestoragelink: stream.file_storage_link
    };
  }

  notificationAvatarAdapterRaw(avatar: Centrifuge.IAvatar): Avatar.AsObject {
    return {
      ...avatar,
      photosmallurl: avatar.photo_small_url || "",
      photolargeurl: avatar.photo_large_url || ""
    };
  }

  notificationUserAdapterRaw(user: Centrifuge.IUser): User.AsObject {
    return {
      ...user,
      firstname: user.first_name || "",
      lastname: user.last_name || "",
      middlename: user.middle_name || "",
      aliasname: "",
      avatar: user.avatar
        ? this.notificationAvatarAdapterRaw(user.avatar)
        : undefined,
      isbot: user.is_bot,
      profileid: user.profile_id,
      portalcode: user.portal_code,
      issuperadmin: user.is_super_admin
    };
  }

  notificationMessageAdapterRaw(
    message: Centrifuge.IMessage
  ): Message.AsObject {
    return {
      ...message,
      cid: "",
      content: message.content || "",
      gallery: message.attach?.gallery
        ? {
            attachmentsList: message.attach.gallery.attachments.map(a =>
              this.notificationAttachmentAdapterRaw(a)
            )
          }
        : undefined,
      contact: message.attach?.contact
        ? this.notificationUserAdapterRaw(message.attach.contact)
        : undefined,
      document: message.attach?.document
        ? this.notificationAttachmentAdapterRaw(message.attach.document)
        : undefined,
      streamid: message.stream_id,
      userid: message.user_id,
      createdat: {
        seconds: new Date(message.created_at || 0).getTime() / 1000,
        nanos: 0
      },
      updatedat: {
        seconds: new Date(message.updated_at || 0).getTime() / 1000,
        nanos: 0
      },
      deleted: false,
      entitiesList: (message.entities || []).map(e =>
        this.notificationEntityAdapterRaw(e)
      ),
      type: message.type,
      servicetype: message.service_type,
      replytomessageid: message.reply_to_message_id,
      originalmessage: message.original_message
        ? this.notificationMessageAdapterRaw(message.original_message)
        : undefined,
      forwardfrom: message.forward_from
        ? this.notificationUserAdapterRaw(message.forward_from)
        : undefined,
      forwardsendername: message.forward_sender_name,
      forwardfromstream: message.forward_from_stream
        ? this.notificationStreamAdapterRaw(message.forward_from_stream)
        : undefined,
      forwardmessageid: message.reply_to_message_id,
      filelist: message.attach?.file_list
        ? {
            attachmentsList: message.attach.file_list.attachments.map(a =>
              this.notificationAttachmentAdapterRaw(a)
            )
          }
        : undefined,
      callduration: message.attach?.call_duration
        ? {
            seconds:
              new Date(message.attach.call_duration || 0).getTime() / 1000,
            nanos: 0
          }
        : undefined
    };
  }

  notificationEntityAdapterRaw(
    entity: Centrifuge.IEntity
  ): MessageEntity.AsObject {
    return {
      ...entity,
      userid: entity.user_id,
      streamid: entity.stream_id
    };
  }

  notificationAttachmentAdapterRaw(
    attachment: Centrifuge.IAttachment
  ): Attachment.AsObject {
    return {
      ...attachment,
      entitiesList: (attachment.entities || []).map(e =>
        this.notificationEntityAdapterRaw(e)
      )
    };
  }

  messageAdapter(
    message: Message.AsObject,
    myId: string,
    users: IUser[],
    user: IUser,
    contactList: IUser[],
    stream: Stream,
    forwardFrom?: IUser | null,
    senderName?: string,
    isForwardChannel?: string
  ): IMessage {
    let forward: IMessage | null = null;
    let reply: IMessage | null = null;
    let guestAuthor: IUser | null = null;
    let systemType: Message.ServiceType | undefined;

    if (message.type === Message.Type.SERVICE) {
      systemType = message.servicetype;
    }

    const guestEntity = message.entitiesList.find(
      e => e.type === MessageEntity.Type.GUEST_NAME
    );

    if (guestEntity) {
      guestAuthor = {
        portalCode: 0,
        id: guestEntity.userid,
        renderName: guestEntity.value + "(Гость)",
        profileId: nullUuid,
        department: "",
        username: "",
        firstName: "",
        isBot: true,
        position: "",
        status: UserStatus.ACTIVE,
        phoneList: [],
        subordinates: [],
        coworkers: [],
        absence: []
      };
    }

    if (message.originalmessage && message.forwardfromstream) {
      const forwardStream = this.streamsAdapter(
        message.forwardfromstream,
        user,
        users,
        contactList
      );

      forward = this.messageAdapter(
        message.originalmessage,
        myId,
        users,
        user,
        contactList,
        forwardStream,
        message.forwardfrom ? this.userAdapter(message.forwardfrom) : null,
        message.forwardsendername,
        message.forwardfromstream.type === Type.CHANNEL
          ? message.forwardfromstream.id
          : undefined
      );
    }

    if (message.replytomessageid && message.originalmessage) {
      reply = this.messageAdapter(
        message.originalmessage,
        myId,
        users,
        user,
        contactList,
        stream
      );
    }

    let author: IUser | undefined;

    if (guestAuthor) {
      author = guestAuthor;
    } else if (forwardFrom) {
      author = forwardFrom;
    } else if (senderName) {
      author = {
        portalCode: 0,
        id: isForwardChannel || nullUuid,
        profileId: nullUuid,
        renderName: senderName,
        department: "",
        username: "",
        firstName: "",
        isBot: true,
        position: "",
        status: UserStatus.ACTIVE,
        phoneList: [],
        subordinates: [],
        coworkers: [],
        absence: []
      };
    }

    const foundUser = users.find(user => user.id === message.userid);

    let documents: IAttachment[] | undefined;

    if (
      message.document &&
      (message.type === Message.Type.ATTACHMENT ||
        message.servicetype === Message.ServiceType.CALL_RECORD)
    ) {
      documents = [this.attachmentAdapter(message.document)];
    } else if (
      message.type === Message.Type.ATTACHMENT &&
      message.filelist?.attachmentsList?.length
    ) {
      documents = message.filelist.attachmentsList.map(a =>
        this.attachmentAdapter(a)
      );
    }

    return {
      reply,
      forward,
      contact: message.contact
        ? this.userAdapter(message.contact, true)
        : undefined,
      entities: message.entitiesList.map(ent => this.entityAdapter(ent)),
      type: message.type,
      documents,
      images: message.gallery
        ? message.gallery.attachmentsList
            .map(att => this.attachmentAdapter(att))
            .filter((_, i) => i < 10)
        : [],
      id: message.id,
      author: author ? { ...author } : foundUser ? { ...foundUser } : undefined,
      body: message.content,
      guest: !!guestAuthor,
      isByMe:
        (guestAuthor && guestAuthor.id === myId) || message.userid === myId,
      date: this.timestampAdapter(message.createdat),
      streamId: message.streamid,
      isForwardChannel: !!isForwardChannel,
      streamName: stream.title,
      streamType: stream.type,
      systemType,
      callDuration:
        systemType === Message.ServiceType.CALL_END &&
        message.callduration?.seconds
          ? message.callduration.seconds
          : undefined
    };
  }

  userAdapter(user: User.AsObject, statusOverride: boolean = false): IUser {
    const isActiveUser =
      ![UserStatus.FIRED, UserStatus.DISABLED].includes(user.status) ||
      statusOverride;

    return {
      firstName: user.firstname,
      profileId: user.profileid,
      portalCode: Number(user.portalcode),
      status: user.status,
      username: user.username,
      department: isActiveUser ? user.department : "",
      avatar:
        user.avatar && isActiveUser
          ? this.avatarAdapter(user.avatar)
          : undefined,
      id: user.id,
      isBot: user.isbot,
      position: isActiveUser ? user.position : "",
      absence: [],
      renderName: isActiveUser
        ? `${user.lastname || ""} ${user.firstname || ""} ${user.middlename || ""}`.trim()
        : "Пользователь удалён",
      email: user.email,
      phoneList: [
        ...(user.phone
          ? [
              {
                title: "Телефон",
                number: user.phone
              }
            ]
          : [])
      ],
      subordinates: [],
      coworkers: []
    };
  }

  avatarAdapter(avatar: Avatar.AsObject): IAvatar {
    return {
      url: avatar.photolargeurl,
      id: avatar.original,
      name: avatar.filename
    };
  }

  notificationAvatarAdapter(avatar: Centrifuge.IAvatar): IAvatar {
    return {
      url: avatar.photo_large_url,
      id: avatar.original,
      name: avatar.filename
    };
  }

  attachmentAdapter(attachment: Attachment.AsObject): IAttachment {
    return {
      name: attachment.file?.filename || "unnamed",
      uuid: attachment.file?.id || nullUuid,
      thumb: {
        id: attachment.file?.meta?.thumb || nullUuid,
        type: "image/png"
      },
      size: attachment.file?.size || 0,
      progress: 1,
      type: attachment.file?.mime || "image/png"
    };
  }

  entityAdapter(entity: MessageEntity.AsObject): IMessageEntity {
    return {
      type: entity.type,
      start: entity.offset,
      length: entity.length,
      userId: entity.userid,
      streamId: entity.streamid,
      value: entity.value,
      lang: entity.language
    };
  }

  notificationPersonAdapterRaw(person: Centrifuge.IPerson): Person.AsObject {
    return {
      ...person,
      userid: person.user_id,
      birthdate: {
        seconds: new Date(person.birth_date || 0).getTime() / 1000,
        nanos: 0
      },
      avatar: person.avatar
        ? this.notificationAvatarAdapterRaw(person.avatar)
        : undefined,
      worknumber: person.work_number,
      contactnumber: person.contact_number,
      mobilenumber: person.mobile_number,
      portalcode: person.portal_code
    };
  }

  personAdapter(
    person: Person.AsObject,
    personObject?: GetPersonResponse.AsObject
  ): IUser {
    const isActiveUser = ![UserStatus.FIRED, UserStatus.DISABLED].includes(
      person.status
    );

    return {
      ...person,
      firstName: person.name,
      profileId: nullUuid,
      portalCode: person.portalcode,
      department: "",
      avatar:
        person.avatar && isActiveUser
          ? this.avatarAdapter(person.avatar)
          : undefined,
      id: person.userid,
      isBot: false,
      renderName: isActiveUser
        ? `${person.surname || ""} ${person.name || ""} ${person.patronym || ""}`.trim()
        : "Пользователь удалён",
      phoneList: [
        ...(person.worknumber
          ? [
              {
                title: "Рабочий",
                number: person.worknumber
              }
            ]
          : []),
        ...(person.mobilenumber
          ? [
              {
                title: "Мобильный",
                number: person.mobilenumber
              }
            ]
          : []),
        ...(person.contactnumber
          ? [
              {
                title: "Контактный",
                number: person.contactnumber
              }
            ]
          : [])
      ],
      birthdate: person?.birthdate
        ? new Date(person.birthdate.seconds * 1000)
        : undefined,
      absence: this.absenceListAdapter(personObject?.absencesList || []),
      location:
        personObject?.place &&
        (personObject.place.country?.name ||
          personObject.place.city?.name ||
          personObject.place.address)
          ? `${
              personObject.place.country?.name
                ? personObject.place.country.name + ","
                : ""
            } ${
              personObject.place.city?.name
                ? personObject.place.city.name + ","
                : ""
            } ${personObject.place.address}`.trim()
          : undefined,
      subordinates: [],
      coworkers: []
    };
  }

  absenceListAdapter(absenceList: Absence.AsObject[]): IAbsence[] {
    return absenceList.map(a => ({
      from: new Date(
        ((a.vacationdatefrom?.seconds || 0) +
          new Date().getTimezoneOffset() * 60) *
          1000
      ),
      to: new Date(
        ((a.vacationdateto?.seconds || 0) +
          new Date().getTimezoneOffset() * 60) *
          1000
      ),
      reason: a.reason,
      otherReason: a.otherreason,
      replacementPerson: a.replacementperson
        ? this.colleagueAdapter(a.replacementperson)
        : undefined
    }));
  }

  colleaguesAdapter(
    colleagues: GetPersonColleaguesResponse.AsObject
  ): IColleagues {
    return {
      funcLeader: colleagues.funcleader
        ? this.colleagueAdapter(colleagues.funcleader)
        : undefined,
      admLeader: colleagues.admleader
        ? this.colleagueAdapter(colleagues.admleader)
        : undefined,
      subordinates: colleagues.subordinatesList.map(s =>
        this.colleagueAdapter(s)
      ),
      coworkers: colleagues.coworkersList.map(c => this.colleagueAdapter(c))
    };
  }

  colleagueAdapter(colleague: Colleague.AsObject): IUser {
    return {
      portalCode: colleague.portalcode,
      id: colleague.userid,
      profileId: nullUuid,
      username: "",
      firstName: colleague.name,
      renderName:
        `${colleague.surname || ""} ${colleague.name || ""} ${colleague.patronym || ""}`.trim(),
      status: UserStatus.ACTIVE,
      department: "",
      avatar: colleague.avatar
        ? {
            name: colleague.avatar.filename,
            url: colleague.avatar.photolargeurl,
            id: colleague.avatar.original
          }
        : undefined,
      position: colleague.position,
      phoneList: [],
      subordinates: [],
      coworkers: [],
      absence: []
    };
  }
}

// noinspection JSUnusedGlobalSymbols
export default {
  install: function (Vue) {
    const service = new MainService();
    const streamService = new StreamService();
    const environmentService = new EnvironmentService();

    Vue.config.globalProperties.service = service;
    Vue.provide("service", service);
    Vue.config.globalProperties.streamService = streamService;
    Vue.provide("streamService", streamService);
    Vue.config.globalProperties.environmentService = environmentService;
    Vue.provide("environmentService", environmentService);
  }
};
