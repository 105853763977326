import { ISettingsStore } from "@/store/modules/settings/i-settings";

import actions from "./actions";
import mutations from "./mutations";

export default {
  namespaced: true,
  state: () => ({
    customServiceList: [],
    developerMode: false,
    stageMode: false,
    maxAvatarSize: 10_485_760,
    maxChatFileSize: 10_485_760,
    maxServiceFileSize: 10_485_760,
    maxBackgroundApps: 2,
    shortLink: "",
    meetDomain: "",
    callDeviceSettings: {},
    isRingtoneEnabled: true,
    callTimeout: 40_000,
    meetLandingUrl: "",
    profileOrigin: "",
    enableMarkAllStreamsAsRead: false,
    showSidebarTooltip: false,
    userManualLink: "",
    isOpenAtLogin: true,
    afkTimeout: 60,
    recordExpirationTime: 90,
    showCallQualityReviewPage: false
  }),
  actions,
  mutations,
  getters: {
    getMaxBackgroundApps(state: ISettingsStore): number {
      return state.developerMode ? state.maxBackgroundApps : 2;
    }
  }
};
