import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, Transition as _Transition, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "image-previewer" }
const _hoisted_2 = {
  ref: "fancyboxContainer",
  class: "image-previewer__fancybox-container"
}
const _hoisted_3 = {
  key: 0,
  class: "image-previewer__bottom-panel"
}
const _hoisted_4 = {
  key: 1,
  ref: "pdfContainer",
  class: "image-previewer__pdf-view-container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TNIcon = _resolveComponent("TNIcon")!
  const _component_TNButton = _resolveComponent("TNButton")!
  const _component_VuePdfEmbed = _resolveComponent("VuePdfEmbed")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["image-previewer__fancybox-loader-background-before", {
        'image-previewer__fancybox-loader-background-before_show':
          _ctx.isShowBackground
      }])
    }, null, 2 /* CLASS */),
    _createElementVNode("div", {
      class: _normalizeClass(["image-previewer__fancybox-loader-background", {
        'image-previewer__fancybox-loader-background_show': _ctx.isShowBackground,
        'image-previewer__fancybox-loader-background_loading': _ctx.isAttachmentsLoad
      }])
    }, [
      (_ctx.isAttachmentsLoad)
        ? (_openBlock(), _createBlock(_component_TNIcon, {
            key: 0,
            class: "image-previewer__fancybox-load-icon",
            name: "load"
          }))
        : _createCommentVNode("v-if", true)
    ], 2 /* CLASS */),
    _createElementVNode("div", {
      class: _normalizeClass([{
        'image-previewer__fancybox-loader-background-after_show':
          _ctx.isShowBackground
      }, "image-previewer__fancybox-loader-background-after"])
    }, null, 2 /* CLASS */),
    _createElementVNode("div", _hoisted_2, null, 512 /* NEED_PATCH */),
    _createVNode(_Transition, { name: "fade-100ms" }, {
      default: _withCtx(() => [
        _createElementVNode("div", {
          class: _normalizeClass(["image-previewer__button-panel", {
          'image-previewer__button-panel_show': _ctx.isShowBackground
        }])
        }, [
          _createVNode(_Transition, { name: "fade-100ms" }, {
            default: _withCtx(() => [
              (_ctx.shareable && !_ctx.isAttachmentsLoad)
                ? (_openBlock(), _createBlock(_component_TNButton, {
                    key: 0,
                    class: "image-previewer__share-button",
                    loading: _ctx.isFileDownload,
                    icon: "share",
                    size: "md",
                    onClick: _ctx.share
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(" Переслать ")
                    ]),
                    _: 1 /* STABLE */
                  }, 8 /* PROPS */, ["loading", "onClick"]))
                : _createCommentVNode("v-if", true)
            ]),
            _: 1 /* STABLE */
          }),
          _createVNode(_Transition, { name: "fade-100ms" }, {
            default: _withCtx(() => [
              (!_ctx.isAttachmentsLoad)
                ? (_openBlock(), _createBlock(_component_TNButton, {
                    key: 0,
                    class: "image-previewer__download-button",
                    icon: "download",
                    size: "md",
                    onClick: _ctx.download
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(" Скачать ")
                    ]),
                    _: 1 /* STABLE */
                  }, 8 /* PROPS */, ["onClick"]))
                : _createCommentVNode("v-if", true)
            ]),
            _: 1 /* STABLE */
          }),
          _createVNode(_component_TNButton, {
            class: "image-previewer__close-button",
            size: "lg",
            rounded: "",
            icon: "close",
            white: "",
            onClick: _ctx.closeWindow
          }, null, 8 /* PROPS */, ["onClick"])
        ], 2 /* CLASS */)
      ]),
      _: 1 /* STABLE */
    }),
    (_ctx.isShowBackground && _ctx.pdfMode)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("span", null, _toDisplayString(_ctx.page) + " / " + _toDisplayString(_ctx.pageCount), 1 /* TEXT */)
        ]))
      : _createCommentVNode("v-if", true),
    (_ctx.imageList.length && _ctx.pdfSource && _ctx.pdfMode)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          (_ctx.page > 1)
            ? (_openBlock(), _createElementBlock("button", {
                key: 0,
                class: "image-previewer__navigation-button image-previewer__navigation-button_pdf image-previewer__navigation-button_previous",
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.previousPage && _ctx.previousPage(...args)))
              }))
            : _createCommentVNode("v-if", true),
          (_ctx.page < _ctx.pageCount)
            ? (_openBlock(), _createElementBlock("button", {
                key: 1,
                class: "image-previewer__navigation-button image-previewer__navigation-button_pdf image-previewer__navigation-button_next",
                onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.nextPage && _ctx.nextPage(...args)))
              }))
            : _createCommentVNode("v-if", true),
          _createVNode(_component_VuePdfEmbed, {
            ref: "pdfComponent",
            class: _normalizeClass(["image-previewer__pdf-embed", { 'image-previewer__pdf-embed_load': _ctx.isAttachmentsLoad }]),
            source: _ctx.pdfSource,
            page: _ctx.page,
            "disable-text-layer": "",
            "disable-annotation-layer": "",
            onRendered: _ctx.handleDocumentRender,
            onLoadingFailed: _ctx.errorHandler
          }, null, 8 /* PROPS */, ["class", "source", "page", "onRendered", "onLoadingFailed"])
        ], 512 /* NEED_PATCH */))
      : _createCommentVNode("v-if", true)
  ]))
}