import { createCommentVNode as _createCommentVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, TransitionGroup as _TransitionGroup } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "layout__dev-badge"
}
const _hoisted_2 = {
  key: 0,
  class: "layout__dev-badge layout__dev-badge_offline"
}
const _hoisted_3 = {
  key: 0,
  class: "layout__dev-badge layout__dev-badge_centrifuge-offline"
}
const _hoisted_4 = { class: "layout__meet-call-container" }
const _hoisted_5 = {
  key: 0,
  class: "layout__meet-call-item"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeaderComponent = _resolveComponent("HeaderComponent")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_Service = _resolveComponent("Service")!
  const _component_Sidebar = _resolveComponent("Sidebar")!
  const _component_ReportForm = _resolveComponent("ReportForm")!
  const _component_MeetCall = _resolveComponent("MeetCall")!
  const _component_PopupList = _resolveComponent("PopupList")!
  const _component_TNToaster = _resolveComponent("TNToaster")!
  const _component_UpdateOverlay = _resolveComponent("UpdateOverlay")!
  const _component_OfflinePopup = _resolveComponent("OfflinePopup")!

  return (_openBlock(), _createElementBlock("main", {
    class: _normalizeClass(["layout", _ctx.classList])
  }, [
    _createCommentVNode(" Кастомный тайтл бар "),
    _createCommentVNode(" <div class=\"layout__drag-bar\"></div> "),
    (!_ctx.isEmbedded)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createVNode(_Transition, { name: "fade" }, {
            default: _withCtx(() => [
              (_ctx.devMode)
                ? (_openBlock(), _createElementBlock("div", _hoisted_1, " Dev "))
                : _createCommentVNode("v-if", true)
            ]),
            _: 1 /* STABLE */
          }),
          _createVNode(_Transition, { name: "fade" }, {
            default: _withCtx(() => [
              (_ctx.devMode && !_ctx.isOnline)
                ? (_openBlock(), _createElementBlock("div", _hoisted_2, " Offline "))
                : _createCommentVNode("v-if", true)
            ]),
            _: 1 /* STABLE */
          }),
          _createVNode(_Transition, { name: "fade" }, {
            default: _withCtx(() => [
              (_ctx.devMode && !_ctx.isCentrifugeOnline)
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, " Centrifuge Offline "))
                : _createCommentVNode("v-if", true)
            ]),
            _: 1 /* STABLE */
          })
        ], 64 /* STABLE_FRAGMENT */))
      : _createCommentVNode("v-if", true),
    _createElementVNode("div", {
      class: _normalizeClass(["layout__main-container", {
        'layout__main-container_lock-scroll': _ctx.isOnboarding && !_ctx.isEmbedded
      }])
    }, [
      (!_ctx.isSimpleLayout && !_ctx.isNoHeader && !_ctx.isEmbedded)
        ? (_openBlock(), _createBlock(_component_HeaderComponent, {
            key: 0,
            class: "layout__header",
            onOpenReportForm: _ctx.openReportForm
          }, null, 8 /* PROPS */, ["onOpenReportForm"]))
        : _createCommentVNode("v-if", true),
      _createElementVNode("main", {
        class: _normalizeClass(["layout__body", { 'layout__body_sub-page': _ctx.isSubpage && !_ctx.isEmbedded }])
      }, [
        _createVNode(_component_router_view)
      ], 2 /* CLASS */)
    ], 2 /* CLASS */),
    (!_ctx.isEmbedded)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.runningApps, (app) => {
            return (_openBlock(), _createBlock(_component_Service, {
              id: app.id,
              key: app.id,
              query: _ctx.$route.query,
              visible: _ctx.isShowApp(app),
              class: _normalizeClass(["layout__service-page", { 'layout__service-page_hide': !_ctx.isShowApp(app) }]),
              onClearQuery: _ctx.clearQuery,
              onUpdateQuery: ($event: any) => (_ctx.updateQuery(app.id, $event))
            }, null, 8 /* PROPS */, ["id", "query", "visible", "class", "onClearQuery", "onUpdateQuery"]))
          }), 128 /* KEYED_FRAGMENT */)),
          (!_ctx.isSimpleLayout)
            ? (_openBlock(), _createBlock(_component_Sidebar, {
                key: 0,
                class: "layout__sidebar"
              }))
            : _createCommentVNode("v-if", true),
          _createVNode(_component_ReportForm, {
            form: _ctx.feedbackForm,
            onSendForm: _ctx.sendForm,
            onClose: _ctx.closeReportForm
          }, null, 8 /* PROPS */, ["form", "onSendForm", "onClose"]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_TransitionGroup, { name: "layout__meet-call" }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.incomingCallWindows, (incomingCallWindow) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: incomingCallWindow.room,
                    class: "layout__meet-call-item"
                  }, [
                    _createVNode(_component_MeetCall, {
                      class: "layout__meet-call",
                      call: incomingCallWindow,
                      loading: !_ctx.callsLoading.length && _ctx.callsLoading.includes(incomingCallWindow.room),
                      onReject: ($event: any) => (_ctx.rejectCall(incomingCallWindow.room)),
                      onAnswer: ($event: any) => (_ctx.answerCall(incomingCallWindow.room)),
                      onClose: ($event: any) => (_ctx.closeIncomingCall(incomingCallWindow.room))
                    }, null, 8 /* PROPS */, ["call", "loading", "onReject", "onAnswer", "onClose"])
                  ]))
                }), 128 /* KEYED_FRAGMENT */))
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode(_Transition, { name: "layout__meet-call" }, {
              default: _withCtx(() => [
                (_ctx.waitingCallWindow)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                      _createVNode(_component_MeetCall, {
                        class: "layout__meet-call",
                        call: _ctx.waitingCallWindow,
                        "waiting-answer": "",
                        rejected: _ctx.isWaitingCallRejected,
                        loading: !_ctx.callsLoading.length && _ctx.callsLoading.includes(_ctx.waitingCallWindow.room),
                        onReject: _ctx.cancelWaitingCall,
                        onClose: _ctx.closeWaitingCall
                      }, null, 8 /* PROPS */, ["call", "rejected", "loading", "onReject", "onClose"])
                    ]))
                  : _createCommentVNode("v-if", true)
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _createVNode(_component_PopupList, {
            "popup-list": _ctx.popupList,
            onClose: _ctx.popupCloseHandler
          }, null, 8 /* PROPS */, ["popup-list", "onClose"]),
          _createVNode(_component_TNToaster, {
            class: _normalizeClass(["layout__toast-list", { 'layout__toast-list_no-padding': _ctx.toastNoPadding && !_ctx.isMobile }]),
            "toast-list": _ctx.toastList,
            bottom: _ctx.isMobile,
            onCloseToast: _ctx.toastCloseHandler
          }, null, 8 /* PROPS */, ["class", "toast-list", "bottom", "onCloseToast"]),
          (_ctx.isUpdateRequired)
            ? (_openBlock(), _createBlock(_component_UpdateOverlay, {
                key: 1,
                class: "layout__update-overlay",
                downloading: _ctx.isUpdateDownloading,
                "download-progress": _ctx.updateDownloadProgress,
                error: _ctx.isUpdateError,
                hard: _ctx.isUpdateHard,
                onLater: _ctx.updateLaterHandler,
                onDownload: _ctx.updateDownloadHandler,
                onRetry: _ctx.updateRetryHandler
              }, null, 8 /* PROPS */, ["downloading", "download-progress", "error", "hard", "onLater", "onDownload", "onRetry"]))
            : _createCommentVNode("v-if", true),
          _createVNode(_component_OfflinePopup, {
            "is-open": !_ctx.isOnline
          }, null, 8 /* PROPS */, ["is-open"])
        ], 64 /* STABLE_FRAGMENT */))
      : _createCommentVNode("v-if", true)
  ], 2 /* CLASS */))
}