import { LSNames, SSNames } from "@/common/constants";
import { Electron } from "@/infrastructure/electron";
import { MainService } from "@/service";

/* eslint-disable no-unused-vars */

const $service = new MainService();
const isBrowser = $service.isBrowser();

export const getRefresh = () => {
  return new Promise<{
    accessToken: string;
    refreshToken: string;
  } | null>((resolve, reject) => {
    if (!window.BroadcastChannel || window.isEmbedded) resolve(null);
    const tokenWorkerBC = new BroadcastChannel("token-worker");

    setTimeout(() => {
      localStorage.removeItem(LSNames.TokenWorkerBusy);
      resolve({
        accessToken: localStorage.getItem(LSNames.AT) || "",
        refreshToken: localStorage.getItem(LSNames.RT) || ""
      });
    }, 5000);
    let id = sessionStorage.getItem(SSNames.TWID);
    let isWaitingForEnd = false;

    if (!id) {
      id = Date.now().toString();
      sessionStorage.setItem(SSNames.TWID, id);
    }
    const messageHandler = (event: MessageEvent | { data: string }) => {
      const isDev = !!localStorage.getItem(LSNames.Dev);

      if (isDev)
        $service.log([
          `%c${new Date().toLocaleString()}: %cRefresh Worker %cGot message`,
          "color: initial;font-style: italic",
          "color: #49a1ff;font-weight: bold",
          "color: #e11b11;font-weight: bold",
          event.data
        ]);
      try {
        const data: {
          id: string;
          type: string;
          status?: "busy" | "became-busy";
          tokens?: { access: string; refresh: string };
        } = event.data;

        if (data && data.id === id && data.type === "refresh-response") {
          if (data.status === "busy") {
            isWaitingForEnd = true;
          } else if (data.status === "became-busy") {
            removeListener(messageHandler);
            resolve(null);
          }
        } else if (
          data.type === "refresh-end-response" &&
          isWaitingForEnd &&
          data.tokens
        ) {
          removeListener(messageHandler);
          resolve({
            accessToken: data.tokens.access,
            refreshToken: data.tokens.refresh
          });
        }
      } catch (e) {
        removeListener(messageHandler);
        reject(e);
      }
    };

    const electronMessageHandler = (_: object, payload: any) => {
      const data = {
        data: payload
      };

      messageHandler(data);
    };

    if (isBrowser) {
      tokenWorkerBC.addEventListener("message", messageHandler);
    } else {
      Electron().ipcRenderer.on("token-worker", electronMessageHandler);
    }
    const removeListener = (callback: (event: MessageEvent) => void) => {
      if (isBrowser) {
        tokenWorkerBC.removeEventListener("message", callback);
      } else {
        Electron().ipcRenderer.removeListener(
          "token-worker",
          electronMessageHandler
        );
      }
    };

    fetchData({
      type: "refresh-request",
      id: sessionStorage.getItem(SSNames.TWID)
    });
  });
};

export const endRefresh = () => {
  if (!window.BroadcastChannel) return;
  const access = localStorage.getItem(LSNames.AT),
    refresh = localStorage.getItem(LSNames.RT);

  if (access && refresh) {
    fetchData({
      type: "refresh-end-request",
      tokens: {
        access,
        refresh
      },
      id: sessionStorage.getItem(SSNames.TWID)
    });
  }
};

const fetchData = (data: object) => {
  const isDev = !!localStorage.getItem(LSNames.Dev);

  if (isDev)
    $service.log([
      `%c${new Date().toLocaleString()}: %cRefresh Worker %cSend message`,
      "color: initial;font-style: italic",
      "color: #49a1ff;font-weight: bold",
      "color: #e11b11;font-weight: bold",
      data
    ]);
  if (isBrowser) {
    new BroadcastChannel("token-worker").postMessage(data);
  } else {
    Electron().ipcRenderer.send("token-worker", data);
  }
};
