import { UserPresenceStatus } from "@superapp/life-proto/pkg-ts/tnlife/chat/v3/presence_pb";
import { InjectionKey } from "vue";
import { createStore, Store } from "vuex";

import { LSNames, nullUuid, validServices } from "@/common/constants";
import Infrastructure from "@/infrastructure";
import { Electron } from "@/infrastructure/electron";
import { MainService, StreamService } from "@/service";
import {
  EmailStatus,
  IIncomingCall,
  IMutationPopup,
  IMutationToast,
  IPageState,
  IPageStateItem,
  IRunningApp,
  IState,
  IStore
} from "@/store/i-store";
import notificationsStore from "@/store/modules/notifications";
import streamStore from "@/store/modules/stream";
import { InitMode } from "@/store/modules/stream/i-stream";
import { IApp } from "@/views/services/i-services";
import {
  appListAdapter,
  siteListAdapter
} from "@/views/services/services.adapters";

import settingsStore from "./modules/settings";
import mutations from "./mutations";

export const key: InjectionKey<Store<IStore>> = Symbol();
const Infra = new Infrastructure();
const streamService = new StreamService();
const service = new MainService();

export default createStore<IState>({
  devtools: process.env.NODE_ENV === "development",
  state: () => ({
    user: null,
    toasts: [],
    timezones: [],
    widgetList: [],
    popupList: [],
    serviceList: null,
    siteList: null,
    fileList: [],
    tutorialTriggerFunction: null,
    serviceUuids: {},
    contactList: null,
    contactListLoad: false,
    colleagues: null,
    chatScrollPosition: [],
    currentChatId: null,
    isAppFocused: true,
    forwardMessage: null,
    feedbackForm: false,
    shareFile: null,
    isShareFileFile: false,
    isMobile: false,
    mobileWindowHeight: 0,
    pageStates: {} as IPageState,
    runningApps: [] as IRunningApp[],
    incomingCallWindows: [],
    waitingCallWindow: null,
    isBrowser: true,
    isOnline: true,
    isWaitingCallRejected: false,
    forceLeavePageHandler: null,
    updateStatus: null,
    lastUpdate: Date.now(),
    colleaguesLoad: false,
    emailStatus: EmailStatus.NotSet,
    deviceID: "",
    presenceList: new Map<string, UserPresenceStatus>()
  }),
  modules: {
    streamStore,
    notificationsStore,
    settingsStore
  },
  mutations,
  actions: {
    init({ commit, dispatch }) {
      dispatch("settingsStore/init");
      commit("getMobile");
    },
    showError(
      { commit },
      error:
        | string
        | {
            message?: string;
            details?: string;
            urgent?: boolean; // todo: реализовать логику сразу отображения попапа с ошибкой на критичные кейсы
          }
    ) {
      if (typeof error === "object") {
        const errorDetails =
          error.details &&
          JSON.stringify(error.details) !== "\"{\\\"isTrusted\\\":true}\"";

        commit("toast", {
          type: "error",
          text: error.message,
          cancelButtonText: errorDetails ? "Подробнее" : undefined,
          canceledCallback: errorDetails
            ? () => {
                let body = error.details || "";

                try {
                  body = `<pre style="overflow: auto">${JSON.stringify(
                    JSON.parse(body),
                    null,
                    2
                  )}</pre>`;
                  commit("showPopup", {
                    title: error.message,
                    body,
                    error: true,
                    buttonsLayout: "vertical",
                    buttons: [
                      {
                        text: "OK",
                        type: "primary",
                        callback: index => {
                          commit("closePopup", index);
                        }
                      }
                    ]
                  } as IMutationPopup);
                } catch (e) {
                  commit("showPopup", {
                    title: error.message,
                    body,
                    error: true,
                    buttonsLayout: "vertical",
                    buttons: [
                      {
                        text: "OK",
                        type: "primary",
                        callback: index => {
                          commit("closePopup", index);
                        }
                      }
                    ]
                  } as IMutationPopup);
                }
              }
            : undefined
        } as IMutationToast);
      } else {
        commit("toast", {
          type: "error",
          text: error
        });
      }
    },
    clearAllData({ state, dispatch }) {
      state.user = null;
      state.serviceList = null;
      state.widgetList = [];
      state.fileList = [];
      state.serviceUuids = {};
      state.contactList = null;
      state.runningApps = [];
      state.chatScrollPosition = [];
      state.pageStates = {};
      dispatch("notificationsStore/clearAllData");
      dispatch("streamStore/clearAllData");
      dispatch("settingsStore/clearAllData");
      state.incomingCallWindows = [];
      dispatch("setWaitingCallWindow", null);
    },
    setServiceList({ state, commit }, appList: IApp[]) {
      state.serviceList = [...appList];
      state.widgetList = [];
      state.serviceList.forEach(service => {
        const foundService = validServices.find(
          vs =>
            !state.serviceUuids[vs.name] &&
            !service.siteAsService &&
            (vs.uuids.includes(service.uuid) ||
              vs.titles.includes(service.title))
        );

        if (foundService) {
          state.serviceUuids[foundService.name] = service.uuid;
        }
        if (service.widgetUrl) {
          commit("appendWidget", service);
        }
      });
    },
    delayNewVersion(
      _,
      payload: {
        date?: number;
        version: string;
      }
    ) {
      localStorage.setItem(LSNames.DelayNewVersion, JSON.stringify(payload));
    },
    openApp(
      { state, getters },
      payload: { id: string; query: Record<string, string>; reopen: boolean }
    ) {
      const runningAppIndex = state.runningApps.findIndex(
        a => a.id === payload.id
      );
      const opened = Date.now();

      if (runningAppIndex !== -1) {
        if (payload.reopen) {
          state.runningApps.splice(runningAppIndex, 1);
        } else {
          state.runningApps[runningAppIndex].opened = opened;
        }
        if (payload.reopen) {
          setTimeout(() => {
            if (state.runningApps.some(ra => ra.id === payload.id)) return;
            state.runningApps.push({
              id: payload.id,
              opened,
              query: payload.query
            });
          }, 10);
        }
      } else {
        if (
          state.runningApps.length >=
          getters["settingsStore/getMaxBackgroundApps"]
        ) {
          const oldestApp = [...state.runningApps].sort((a1, a2) =>
            a1.opened > a2.opened ? 1 : a1.opened < a2.opened ? -1 : 0
          )[0];
          const oldestAppIndex = state.runningApps.findIndex(
            a => oldestApp && a.id === oldestApp.id
          );

          if (oldestAppIndex !== -1) {
            state.runningApps.splice(oldestAppIndex, 1);
          }
        }
        setTimeout(() => {
          if (state.runningApps.some(ra => ra.id === payload.id)) return;
          state.runningApps.push({
            id: payload.id,
            opened,
            query: payload.query
          });
        }, 10);
      }
    },
    updateOpenAppQuery(
      { state },
      payload: { appId: string; query: Record<string, string> }
    ) {
      const app = state.runningApps.find(a => a.id === payload.appId);

      if (app) {
        app.query = payload.query;
      }
    },
    setIncomingCallWindow(
      { state },
      event: {
        room: string;
        call: IIncomingCall | null;
      }
    ) {
      const callIndex = state.incomingCallWindows.findIndex(
        icw => icw.room === event.room
      );

      if (callIndex !== -1) {
        if (event.call) {
          state.incomingCallWindows.splice(callIndex, 1, event.call);
        } else {
          state.incomingCallWindows.splice(callIndex, 1);
        }
      } else if (event.call) {
        state.incomingCallWindows.push(event.call);
      }
    },
    setWaitingCallWindow({ state }, call: IIncomingCall | null) {
      if (
        state.waitingCallWindow &&
        call &&
        state.waitingCallWindow.room === call.room
      ) {
        return;
      }
      if (call) {
        state.isWaitingCallRejected = false;
      }
      state.waitingCallWindow = call;
    },
    setWaitingCallRejected({ state }, rejected: boolean) {
      state.isWaitingCallRejected = rejected;
    },
    meet({ state, commit }, payload: { roomId: string; force?: boolean }) {
      if (state.isBrowser) {
        if (location.pathname.startsWith("/meet/")) {
          window.open("/meet/" + payload.roomId, "_self");

          return;
        }
        const meetWindow = window.open("/meet/" + payload.roomId, "_blank");

        setTimeout(() => {
          if (!meetWindow || meetWindow.closed) {
            commit("toast", {
              text: "Браузер запретил отрытие окна встречи",
              type: "warn",
              duration: 10000
            });
          }
        }, 100);
      } else {
        Electron().ipcRenderer.send("open-meet", payload);
      }
    },
    async loadColleagues({ state, dispatch, commit }) {
      if (!state.colleagues?.length) {
        try {
          state.colleaguesLoad = true;
          const { coworkersList, subordinatesList, funcleader, admleader } =
            await Infra.user.GetPersonColleagues(state.user?.portalCode || 0);
          const users = coworkersList
            .concat(subordinatesList)
            .map(c => streamService.colleagueAdapter(c));

          if (funcleader) {
            users.unshift(streamService.colleagueAdapter(funcleader));
          }
          if (admleader && admleader.portalcode !== funcleader?.portalcode) {
            users.unshift(streamService.colleagueAdapter(admleader));
          }

          if (users.length) {
            const presenceList = await Infra.user.GetPresence(
              users.map(u => u.id).filter(id => id !== nullUuid)
            );

            presenceList.forEach(p => {
              dispatch("setPresenceList", p);
            });
          }
          await dispatch("streamStore/addUsers", users);
          commit("setColleagues", users);
        } catch {}
        state.colleaguesLoad = false;
      }
    },
    async initAppList({ state, dispatch, commit }) {
      try {
        const response = await Infra.services.GetServicesList();

        await dispatch(
          "setServiceList",
          appListAdapter(response.appsList, response.sitesList)
        );
        commit("setSiteList", siteListAdapter(response.sitesList || []));
      } catch {
        state.serviceList = null;
        state.siteList = null;
      }
    },
    updateData({ state, dispatch }) {
      const time = Date.now();
      const hours = state["settingsStore"].afkTimeout * 60 * 1000;

      if (time - state.lastUpdate > hours && localStorage.getItem(LSNames.AT)) {
        if (localStorage.getItem(LSNames.Dev) === "1")
          service.log([
            `%c${new Date().toLocaleString()}: %cData %cUpdate`,
            "color: initial;font-style: italic",
            "color: #49a1ff;font-weight: bold;",
            "color: initial;font-weight: bold;"
          ]);
        dispatch("initAppList");
        dispatch("streamStore/init", InitMode.Background);
      }
      state.lastUpdate = time;
    },
    setPresenceList({ state }, presenceList: {
      id: string;
      status: UserPresenceStatus;
    }[]) {
      state.presenceList = presenceList.reduce((acc, presence) => {
        acc.set(presence.id, presence.status)

        return acc;
      }, new Map<string, UserPresenceStatus>());
    },
    addPresence({ state }, presence: {
      id: string;
      status: UserPresenceStatus;
    }) {
      state.presenceList.set(presence.id, presence.status)
    }
  },
  getters: {
    getCreateChatPageState(state): IPageStateItem | null {
      const name = "create-chat";
      const chatPagesNames = [
        "create-chat",
        "chat-page",
        "chat-info",
        "chat-user-page"
      ];
      const stateNames = Object.keys(state.pageStates);

      if (
        stateNames
          .filter(sn => chatPagesNames.includes(sn))
          .sort((key1, key2) => {
            const time1 = state.pageStates[key1]?.time || 0;
            const time2 = state.pageStates[key2]?.time || 0;

            return time1 >= time2 ? (time1 > time2 ? -1 : 1) : 0;
          })[0] === name
      ) {
        return state.pageStates[name] || null;
      }

      return null;
    },
    getChatListPageState(state): { item: IPageStateItem; name: string } | null {
      const chatPagesNames = [
        "create-chat",
        "chat-page",
        "chat-info",
        "chat-user-page"
      ];
      const stateNames = Object.keys(state.pageStates);
      const firstStateName =
        stateNames
          .filter(sn => chatPagesNames.includes(sn))
          .sort((key1, key2) => {
            const time1 = state.pageStates[key1]?.time || 0;
            const time2 = state.pageStates[key2]?.time || 0;

            return time1 >= time2 ? (time1 > time2 ? -1 : 1) : 0;
          })[0] || "";
      const item = state.pageStates[firstStateName];

      return item ? { item, name: firstStateName } : null;
    },
    getUserPageState(state): IPageStateItem | null {
      return state.pageStates["user-page"] || null;
    },
    getContactsPageState(state): IPageStateItem | null {
      return state.pageStates["contacts"] || null;
    },
    getChatListSearchState(state): IPageStateItem | null {
      return state.pageStates["chat-list"] || null;
    }
  }
});
