import { Profile } from "@superapp/life-proto/pkg-ts/tnlife/chat/v3/profile_pb";

import { StreamService } from "@/service";
import { IProfile } from "@/store/i-store";
import { Centrifuge } from "@/store/modules/stream/i-stream";

const streamService = new StreamService();

export const profileNotificationAdapter = (
  profile: Centrifuge.IProfile
): IProfile | null => {
  if (profile.user) {
    return {
      ...profile.user,
      profileId: profile.user.profile_id,
      email: profile.email,
      employeeEmail: profile.employee_email,
      firstName: profile.user.first_name || "",
      avatar: profile.user.avatar
        ? streamService.notificationAvatarAdapter(profile.user.avatar)
        : undefined,
      isBot: profile.user.is_bot,
      renderName:
        `${profile.user.last_name || ""} ${profile.user.first_name || ""} ${profile.user.middle_name || ""}`.trim(),
      phoneList: [
        ...(profile.phone
          ? [
              {
                title: "Телефон",
                number: profile.phone
              }
            ]
          : [])
      ],
      coworkers: [],
      portalCode: Number(profile.portal_code),
      subordinates: [],
      absence: []
    };
  }

  return null;
};

export const profileAdapter = (profile: Profile.AsObject): IProfile | null => {
  if (profile.user) {
    return {
      ...profile.user,
      profileId: profile.user.profileid,
      email: profile.email,
      employeeEmail: profile.employeeEmail,
      firstName: profile.user.firstname,
      avatar: profile.user.avatar
        ? streamService.avatarAdapter(profile.user.avatar)
        : undefined,
      isBot: profile.user.isbot,
      renderName:
        `${profile.user.lastname || ""} ${profile.user.firstname || ""} ${profile.user.middlename || ""}`.trim(),
      phoneList: [
        ...(profile.phone
          ? [
              {
                title: "Телефон",
                number: profile.phone
              }
            ]
          : [])
      ],
      coworkers: [],
      portalCode: Number(profile.portalCode),
      subordinates: [],
      absence: []
    };
  }

  return null;
};
