<template>
  <div
    class="service-page"
    :class="{ 'service-page_no-animation': noAnimation }"
  >
    <transition name="header-slide">
      <div
        v-if="
          showNoAccessWindow ||
            showErrorWindow ||
            splashLoadState < SplashState.Loaded
        "
        class="service-page__header"
      >
        {{ app?.title }}
      </div>
    </transition>
    <div v-if="app" class="service-page__button-container">
      <div
        v-if="isCustomService && devMode"
        class="service-page__dev-badge"
        @click="openSetUrlPopup"
      >
        Dev
      </div>
      <button
        :id="'button-' + app?.uuid"
        class="service-page__button service-page__button_options"
        :style="{
          backgroundColor: buttonsBackgroundColor,
          '--divider-color': buttonsDividerColor,
          '--divider-background-color': buttonsBackgroundColor
        }"
        @click="openOptionsMenu"
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16.5 13C17.3284 13 18 12.3284 18 11.5C18 10.6716 17.3284 10 16.5 10C15.6716 10 15 10.6716 15 11.5C15 12.3284 15.6716 13 16.5 13ZM13 11.5C13 12.3284 12.3284 13 11.5 13C10.6716 13 10 12.3284 10 11.5C10 10.6716 10.6716 10 11.5 10C12.3284 10 13 10.6716 13 11.5ZM8 11.5C8 12.3284 7.32843 13 6.5 13C5.67157 13 5 12.3284 5 11.5C5 10.6716 5.67157 10 6.5 10C7.32843 10 8 10.6716 8 11.5Z"
            fill="#ffffff"
            :style="{
              fill: buttonsIconColor
            }"
          />
        </svg>
      </button>
      <DropdownMenu
        top="36"
        right="-46"
        :button-id="'button-' + app?.uuid"
        :show-menu="isOptionsMenuVisible"
        :menu-items="optionsMenuItems"
        button-class-name="service-page__button"
        @select="optionSelectHandler"
        @close="closeOptionsMenu"
      />
      <button
        title="Закрыть страницу сервиса"
        :style="{
          backgroundColor: buttonsBackgroundColor
        }"
        class="service-page__button service-page__button_close"
        @click="closeService"
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M18.7071 6.70711C19.0976 6.31658 19.0976 5.68342 18.7071 5.29289C18.3166 4.90237 17.6834 4.90237 17.2929 5.29289L12 10.5858L6.70711 5.29289C6.31658 4.90237 5.68342 4.90237 5.29289 5.29289C4.90237 5.68342 4.90237 6.31658 5.29289 6.70711L10.5858 12L5.29289 17.2929C4.90237 17.6834 4.90237 18.3166 5.29289 18.7071C5.68342 19.0976 6.31658 19.0976 6.70711 18.7071L12 13.4142L17.2929 18.7071C17.6834 19.0976 18.3166 19.0976 18.7071 18.7071C19.0976 18.3166 19.0976 17.6834 18.7071 17.2929L13.4142 12L18.7071 6.70711Z"
            fill="#ffffff"
            :style="{
              fill: buttonsIconColor
            }"
          />
        </svg>
      </button>
    </div>
    <iframe
      v-if="app && url && !(showNoAccessWindow || showErrorWindow)"
      ref="iframe"
      class="service-page__frame"
      :class="{
        'service-page__frame_disabled': filePicker.isShowPopup,
        'service-page__frame_loading': splashLoadState !== SplashState.Hidden
      }"
      :src="url"
      allowfullscreen
    ></iframe>
    <div
      v-else-if="showNoAccessWindow || showErrorWindow"
      class="service-page__no-access-window-container"
    >
      <div class="service-page__error-window">
        <template v-if="showNoAccessWindow">
          <div
            class="service-page__error-image service-page__error-image_no-access"
          ></div>
          <h3 class="service-page__error-title">
            У вас нет доступа к сервису
          </h3>
          <TNButton
            link
            size="md"
            class="service-page__error-request-access-button"
            @click="requestAccess"
          >
            Запросить доступ
          </TNButton>
        </template>
        <template v-else>
          <div
            class="service-page__error-image service-page__error-image_could-not-load"
          ></div>
          <h3 class="service-page__error-title">
            Сервис не загрузился
          </h3>
          <p class="service-page__error-annotation">
            Проверьте доступ к интернету и перезапустите сервис. Если это не
            помогает, сообщите нам.
          </p>
          <TNButton
            link
            size="md"
            class="service-page__error-request-access-button"
            icon="repeat"
            @click="reloadService"
          >
            Перезагрузить
          </TNButton>
          <TNButton
            link
            size="md"
            class="service-page__error-request-access-button service-page__error-request-access-button_report"
            icon="help"
            @click="reportError"
          >
            Сообщить о проблеме
          </TNButton>
        </template>
      </div>
    </div>
    <div v-else class="service-page__error-badge">
      Сервис не найден
    </div>
    <Popup
      :visible="filePicker.isShowPopup"
      custom-class="service-page__file-pick-popup"
      :popup="filePickerPopup"
      @close="sendCancelFilePick"
    >
      <ul v-if="selectedFiles.length" class="service-page__selected-file-list">
        <li
          v-for="(file, index) in selectedFiles"
          ref="fileDOMItems"
          :key="file.name"
          class="service-page__selected-file-item"
        >
          <TNFileIcon
            class="service-page__file-icon"
            :extension="getFileExtension(file.name)"
          />
          <div class="service-page__file-text-container">
            <p
              class="service-page__selected-file-name"
              :class="{
                'service-page__selected-file-name_error': file.error
              }"
            >
              {{ file.name }}
            </p>
            <p class="service-page__file-size">
              {{ prettifySize(file.file.size) }}
            </p>
          </div>

          <div class="service-page__file-item-button-container">
            <TNIcon
              v-if="isFilesUploading && !file.uploadedFile"
              name="load"
              class="service-page__file-item-loader"
            />
            <TNIcon
              v-if="file.uploadedFile"
              name="check"
              class="service-page__remove-uploaded-button"
            />
            <TNIcon
              v-if="file.error"
              class="service-page__file-item-error"
              name="warning"
            />
            <TNIcon
              v-if="file.warn"
              class="service-page__file-item-warn"
              name="warning"
            />
            <div v-if="file.warn" class="service-page__file-item-warn"></div>
            <TNButton
              v-if="!isFilesUploading"
              class="service-page__remove-selection-button"
              size="md"
              white
              icon="delete"
              @click="removeSelectedFile(index)"
            />
          </div>
        </li>
      </ul>
      <div
        v-if="selectedFiles.length < maxFiles && !isFilesUploading"
        class="service-page__file-area"
        :class="{
          'service-page__file-area_drag-over': isFileDragging
        }"
        @drop="dragDrop"
        @dragover="dragStart"
      >
        <span v-if="isFileDragging">{{ uploadReleaseText }}</span>
        <template v-else>
          <label
            for="fileInput"
            class="service-page__file-label"
          >{{ uploadLabel }} </label>&nbsp;<span class="service-page__drag-drop-hint">{{
            uploadText
          }}</span>
        </template>
      </div>
      <input
        id="fileInput"
        ref="fileInput"
        type="file"
        class="service-page__file-input"
        :disabled="isFilesUploading || selectedFiles.length > maxFiles"
        :multiple="filePicker.isMultipleFiles"
        :accept="filePicker.isImagesOnly ? imageExtensions : ''"
        @input="updateSelectedFiles"
      />
      <p
        v-if="maxFiles < selectedFiles.length"
        class="service-page__files-badge service-page__files-badge_overdo"
      >
        Слишком много файлов. Удалите несколько, чтобы осталось
        {{ maxFilesLabel }}.
      </p>
      <p
        v-if="isSomeFileTooBig"
        class="service-page__files-badge service-page__files-badge_overdo"
      >
        {{ tooBigFilesText }}
      </p>
      <p
        v-if="isFilesHaveErrors"
        class="service-page__files-badge service-page__files-badge_error"
      >
        {{ loadFilesErrorText }}
      </p>
    </Popup>
    <Popup
      :visible="isSetUrlPopupVisible"
      custom-class="service-page__custom-url-popup"
      :popup="setUrlPopup"
      :full-height="isMobile"
      @close="closeSetUrlPopup"
    >
      <TNInput
        v-model="urlText"
        placeholder="Введите ссылку"
        class="service-page__custom-url-input"
        @enter="setUrl(false)"
      />
    </Popup>
    <Popup
      :visible="isConfirmPopupVisible"
      custom-class="service-page__email-confirm-popup"
      :popup="confirmPopup"
      @close="closeEmailConfirmPopup"
    />
    <ImagePreviewer
      :image-list="previewImageList"
      :init-index="0"
      :pdf-mode="previewPdf"
      @close="previewCloseHandler"
      @download="downloadPreviewedFile"
      @error="previewError"
      @rendered="previewSuccess"
    />
    <div class="service-page__drag-drop-label"></div>
    <div
      class="service-page__splash-container"
      :class="{
        'service-page__splash-container_loaded':
          splashLoadState === SplashState.Loaded,
        'service-page__splash-container_hide':
          splashLoadState === SplashState.Hidden
      }"
    >
      <div class="service-page__splash-animation-container">
        <div class="service-page__splash-wave-item"></div>
        <div class="service-page__splash-wave-item"></div>
        <div class="service-page__splash-wave-item"></div>
        <div class="service-page__splash-filler"></div>
        <Avatar
          :id="app?.logoId"
          class="service-page__splash-logo"
          application
        />
      </div>
      <h2 class="service-page__name">
        {{ app?.title }}
      </h2>
    </div>
  </div>
</template>
<script lang="ts" src="./service.ts"></script>
<style src="./service.css"></style>
