import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TNUserPicture = _resolveComponent("TNUserPicture")!

  return (_openBlock(), _createBlock(_component_TNUserPicture, {
    text: _ctx.text,
    icon: _ctx.icon,
    image: _ctx.image,
    size: _ctx.size,
    "icon-size": _ctx.application ? 48 : 0,
    square: _ctx.isAttachmentImage || _ctx.isAttachmentFile || _ctx.application,
    class: _normalizeClass(["avatar", {
      avatar_application: _ctx.application,
      avatar_favorite: _ctx.isFavorite,
      avatar_attachment: _ctx.isAttachmentImage,
      'avatar_attachment-file': _ctx.isAttachmentFile,
      avatar_meet: _ctx.meeting
    }]),
    status: _ctx.avatarStatus
  }, null, 8 /* PROPS */, ["text", "icon", "image", "size", "icon-size", "square", "class", "status"]))
}