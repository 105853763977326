import { computed } from "vue";
import { useStore } from "vuex";

import { key } from "@/store";

export const useError = () => {
  const $store = useStore(key);

  const toastList = computed(() => $store.state.toasts);

  return (
    errorData:
      | string
      | {
          message?: string;
          details?: string;
          urgent?: boolean;
        }
  ) => {
    if (
      !toastList.value.length ||
      !toastList.value.some(
        t =>
          (typeof errorData === "string" && t.text === errorData) ||
          (typeof errorData !== "string" && t.text === errorData.message)
      )
    ) {
      $store.dispatch("showError", errorData);
    }
  };
};
