import { Centrifuge, State, Subscription } from "centrifuge";

import { LSNames } from "@/common/constants";
import Infrastructure from "@/infrastructure";
import { endRefresh, getRefresh } from "@/infrastructure/token-worker";
import { MainService } from "@/service";
import { Centrifuge as CentrifugeNS } from "@/store/modules/stream/i-stream";

const Infra = new Infrastructure();

// noinspection JSUnusedGlobalSymbols
export class WebSocket {
  public centrifuge: Centrifuge | null = null;
  public userId: string | undefined;
  public notificationSubscribe: Centrifuge | null = null;
  private readonly onConnected: Function | null = null;
  private embedded: boolean = false;

  centrifugeUrl = "";
  subscriptionList: {
    channel: string;
    subscription: Subscription;
  }[] = [];

  constructor(developerMode: boolean, onConnected: Function | null = null) {
    this.onConnected = onConnected;
    const service = new MainService();

    this.centrifugeUrl = service.isBrowser()
      ? window.VUE_APP_CENTRIFUGE_URL
      : service.getEnvironment()?.centrifugeEndpoint || "";
    if (this.centrifugeUrl) {
      this.centrifuge = new Centrifuge(this.centrifugeUrl, {
        minReconnectDelay: 200,
        maxReconnectDelay: 400,
        debug: developerMode,
        getToken: async (): Promise<string> => {
          try {
            if (this.embedded) {
              return localStorage.getItem(LSNames.EWSAT) || "";
            }
            const response = await getRefresh();

            if (response) {
              return response.accessToken;
            }
            await Infra.auth.RefreshToken();
            endRefresh();
          } catch {
            service.logout(true);
          }

          return localStorage.getItem(LSNames.AT) || "";
        }
      });
    }
  }

  public connect(userId?: string, embedded: boolean = false): void {
    if (this.centrifugeUrl) {
      this.embedded = embedded;
      this.userId = userId;
      const service = new MainService();
      const externalCentrifugeUrl = service.isBrowser()
        ? window.VUE_APP_CENTRIFUGE_URL
        : service.getEnvironment()?.centrifugeEndpoint || "";

      if (this.centrifugeUrl !== externalCentrifugeUrl) {
        this.centrifugeUrl = externalCentrifugeUrl;
        this.centrifuge = new Centrifuge(this.centrifugeUrl);
      }
      const token = localStorage.getItem(embedded ? LSNames.EWSAT : LSNames.AT);

      if (
        this.centrifuge &&
        this.centrifuge.state !== State.Connected &&
        token
      ) {
        this.centrifuge.setToken(token);
        this.centrifuge.connect();
        this.centrifuge.on("connected", () => {
          if (this.centrifuge && this.userId) {
            if (this.onConnected) {
              this.onConnected();
            }
          }
        });
      }
    }
  }

  public unsubscribe(channel: string): void {
    if (this.centrifuge) {
      const subscriptionIndex = this.subscriptionList.findIndex(
        s => s.channel === channel
      );

      if (subscriptionIndex !== -1) {
        this.subscriptionList[subscriptionIndex].subscription.unsubscribe();
        this.subscriptionList.splice(subscriptionIndex, 1);
      }
    }
  }

  public isSocketConnected(): boolean {
    return !!this.centrifuge && this.centrifuge.state === State.Connected;
  }

  public setPublishHandler(
    // eslint-disable-next-line no-unused-vars
    callback: (notification: CentrifugeNS.Notification) => void
  ) {
    this.centrifuge?.on("publication", ctx => {
      callback(ctx.data);
    });
  }

  public disconnect() {
    this.centrifuge && this.centrifuge.disconnect();
  }
}
