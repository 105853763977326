import {
  Stream as ExternalStream,
  SubscribedStream
} from "@superapp/life-proto/pkg-ts/tnlife/chat/v3/stream_pb";

import { nullUuid } from "@/common/constants";
import Infrastructure from "@/infrastructure";
import { StreamService } from "@/service";
import { IUser } from "@/store/i-store";
import { Stream } from "@/store/modules/stream/i-stream";

import Type = ExternalStream.Type;
import SystemType = ExternalStream.SystemType;
import Visible = ExternalStream.Visible;

const streamService = new StreamService();
const Infra = new Infrastructure();

export const subscribedStreamsAdapter = (
  subStream: SubscribedStream.AsObject[],
  user: IUser,
  users: IUser[],
  contactList: IUser[]
): Stream[] => {
  return subStream.map(subItem => {
    const message = subItem.lastmessage
      ? streamService.messageParser(subItem.lastmessage)
      : null;
    const stream = streamService.streamsAdapter(
      subItem.stream!,
      user,
      users,
      contactList
    );
    const lastMessage = message
      ? streamService.messageAdapter(
          message,
          user?.id,
          users,
          user,
          contactList,
          stream
        )
      : null;
    const interlocutorId = subItem.stream?.meta?.interlocutor;
    const type = subItem.stream?.type || Type.DIRECT;
    const isPersonalChat = !!subItem.stream?.issavedmessages;
    const isCanWrite = !!(
      subItem.stream &&
      ((subItem.stream.type === Type.CHANNEL &&
        subItem.stream.meta &&
        subItem.stream.meta.owner === user?.id) ||
        subItem.stream.type !== Type.CHANNEL)
    );
    const isCanLeave =
      subItem.stream?.type === Type.CHANNEL
        ? subItem.stream?.meta?.owner === user?.id &&
          subItem.stream?.systemtype !== SystemType.SUPER_CHANNEL
        : stream.type === Type.DIRECT
          ? subItem.stream?.meta?.interlocutor !== user?.id ||
            subItem.stream?.meta?.owner === nullUuid
          : !subItem.stream?.meet;
    const createDate = streamService.timestampAdapter(
      subItem.stream?.createdat
    );

    return new Stream({
      id: subItem.stream?.id || nullUuid,
      type,
      createDate,
      isPinned: subItem.stream?.meta?.pinned,
      interlocutorId,
      lastReadMessageId: subItem.myreadmessage,
      isCanWrite,
      isCanLeave,
      messages: [...(lastMessage ? [lastMessage] : [])],
      isPersonalChat,
      iconUuid: streamService.getIconUuid(
        type,
        subItem.stream?.logo?.original,
        interlocutorId,
        users
      ),
      iconUrl: streamService.getIconUrl(
        type,
        subItem.stream?.logo?.photolargeurl,
        interlocutorId,
        users
      ),
      title: streamService.getChatName(
        type,
        isPersonalChat,
        users,
        subItem.stream?.title || "Безымянный",
        interlocutorId,
        contactList
      ),
      lastMessageId: lastMessage?.id || null,
      lastMessageAuthorName: lastMessage
        ? streamService.getLastMessageAuthor(
            type,
            interlocutorId,
            lastMessage,
            users,
            user,
            contactList
          )
        : null,
      lastMessageBody: lastMessage
        ? streamService.getChatBody(lastMessage)
        : null,
      lastMessageDateString: streamService.getChatTime(
        createDate,
        lastMessage?.date
      ),
      lastMessageDate: lastMessage?.date ? lastMessage?.date : createDate,
      private: subItem.stream?.visible === Visible.PRIVATE || false,
      description: subItem.stream?.description || "",
      canEdit: subItem.stream?.meta?.owner === user?.id,
      ownerId: subItem.stream?.meta?.owner || nullUuid,
      isSuperChannel: subItem.stream?.systemtype === SystemType.SUPER_CHANNEL,
      callData: null,
      oncomingMeeting: null,
      meetData: subItem.stream?.meet ? subItem.stream.meet : null,
      isArchived: !!subItem.stream?.meta?.archived
    });
  });
};

export const getUsersFromSubscribedList = async (
  subStream: SubscribedStream.AsObject[],
  alreadyLoadedUserList: IUser[] = []
): Promise<IUser[]> => {
  const userList: IUser[] = alreadyLoadedUserList;

  for (let i = 0; i < subStream.length; i++) {
    const sub = subStream[i];
    const isAlreadyInList = userList.some(
      user =>
        sub.lastmessageuser &&
        user.id === sub.lastmessageuser.id &&
        sub.lastmessageuser.id !== nullUuid
    );

    if (!isAlreadyInList) {
      if (sub.lastmessageuser?.id && sub.lastmessageuser.id !== nullUuid) {
        userList.push(streamService.userAdapter(sub.lastmessageuser));
      } else if (
        sub.stream?.meta?.interlocutor &&
        sub.stream.meta.interlocutor !== nullUuid
      ) {
        try {
          const user = await Infra.user.GetUserInfoById(
            sub.stream.meta.interlocutor
          );

          if (user) {
            userList.push(streamService.userAdapter(user));
          }
        } catch {}
      }
    }
  }

  return userList;
};
