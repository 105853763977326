import {
  computed,
  defineComponent,
  nextTick,
  onMounted,
  ref,
  watch
} from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";

import DropdownMenu from "@/components/dropdown-menu/dropdown-menu.vue";
import { INavigation, SidebarElement } from "@/components/sidebar/i-sidebar";
import servicesList from "@/components/sidebar/services-list";
import { key } from "@/store";
import { EmailStatus } from "@/store/i-store";

import NavigationItem from "./components/navigation-item/navigation-item.vue";
import { mainNavigationList } from "./navigation-list";

export default defineComponent({
  name: "Sidebar",
  components: {
    NavigationItem,
    DropdownMenu
  },
  setup: () => {
    const $route = useRoute();
    const $router = useRouter();
    const $store = useStore(key);

    const serviceListContainer = ref<HTMLElement | null>(null);
    const moreButton = ref<InstanceType<typeof NavigationItem> | null>(null);
    const lastIndex = ref<number>(0);
    const moreBottom = ref<number>(0);
    const moreLeft = ref<number>(80);
    const isShowMoreMenu = ref<boolean>(false);
    const isTooltipVisible = ref<boolean>(false);
    const tooltipStyle = ref<Partial<CSSStyleDeclaration>>({ top: "" });
    const tooltipBody = ref<string>("");

    let mouseLeaveTimeout: NodeJS.Timeout | null = null;

    const moreNavigationItem: INavigation = {
      name: "Ещё",
      path: "",
      iconName: "more-horizontal"
    };

    onMounted(() => {
      navigationListWatcher();
      window.addEventListener("resize", () => {
        navigationListWatcher();
        closeMoreMenu();
      });
    });

    const sortedNavigationList = computed<INavigation[]>(() =>
      servicesList($store.state.serviceUuids).filter(s => !s.hide)
    );
    const navigationList = computed<INavigation[]>(() =>
      sortedNavigationList.value.filter((_, i) => i <= lastIndex.value)
    );
    const notificationList = computed<
      {
        count: number;
        muted: boolean;
      }[]
    >(() => {
      return mainNavigationList.map(navigation => {
        if (navigation && navigation.path === "/chat") {
          const streamUnreadList = $store.state.streamStore.streamList.filter(
            stream =>
              stream.lastReadMessageId !== stream.lastMessageId &&
              !stream.isArchived &&
              stream.lastMessageId
          );

          return {
            count: streamUnreadList.length,
            muted: streamUnreadList.every(s =>
              s.getChatMutedState($store.state.notificationsStore)
            )
          };
        }

        return {
          count: 0,
          muted: true
        };
      });
    });
    const isShowMoreButton = computed<boolean>(
      () => lastIndex.value < sortedNavigationList.value.length - 1
    );
    const navigationMoreList = computed<INavigation[]>(() =>
      sortedNavigationList.value.filter((_, index) => index > lastIndex.value)
    );
    const isMoreBackground = computed<boolean>(() => {
      const paths = navigationMoreList.value.map(sn => sn.path);

      return $store.state.runningApps.some(a =>
        paths.some(p => p.includes(a.id))
      );
    });
    const isMoreBackgroundActive = computed<boolean>(() =>
      navigationMoreList.value.some(nm => isActive(nm))
    );
    const showSidebarTooltip = computed<boolean>(
      () => $store.state.settingsStore.showSidebarTooltip
    );
    const profileNavigationItem = computed<INavigation>(() => ({
      name: "Профиль",
      path: "/profile",
      imageName: $store.state.user?.renderName,
      image: $store.state.user?.avatar?.url
    }));
    const profileNotifications = computed<{ count: number; muted: boolean }>(
      () => ({
        count: +($store.state.emailStatus !== EmailStatus.Confirmed),
        muted: false
      })
    );

    watch(
      () => sortedNavigationList.value,
      () => {
        navigationListWatcher();
      }
    );

    const navItemClickHandler = (navigation: SidebarElement) => {
      if (navigation && navigation !== "flex") {
        if (navigation.click) {
          navigation.click();
        } else if (navigation.path) {
          $router.push(navigation.path);
        }
      }
      navigationItemMouseLeaveHandler();
      closeMoreMenu();
    };
    const navigationListWatcher = async () => {
      if (serviceListContainer.value) {
        const containerHeight = serviceListContainer.value.clientHeight;

        lastIndex.value = Infinity;
        await nextTick();
        let heightCount = -1;
        const serviceItemsList = serviceListContainer.value.querySelectorAll(
          ".sidebar__navigation-item"
        );

        for (let i = 0; i < serviceItemsList.length; i++) {
          const item = serviceItemsList[i];

          heightCount += item.clientHeight;
          if (heightCount > containerHeight) {
            lastIndex.value = i - 2;
            break;
          } else {
            lastIndex.value = i;
          }
        }
      }
    };
    const moreClickHandler = () => {
      isShowMoreMenu.value = true;
      if (moreButton.value) {
        moreBottom.value =
          window.innerHeight -
          moreButton.value.$el.offsetTop -
          moreButton.value.$el.clientHeight;
      }
    };
    const moreSelectHandler = (element: string) => {
      $router.push(element);
      closeMoreMenu();
    };
    const closeMoreMenu = () => {
      isShowMoreMenu.value = false;
    };
    const navigationItemMouseEnterHandler = (
      event: MouseEvent,
      navigationItem: INavigation
    ) => {
      if (!showSidebarTooltip.value) return;
      if (mouseLeaveTimeout) {
        clearTimeout(mouseLeaveTimeout);
        mouseLeaveTimeout = null;
      }
      tooltipBody.value = navigationItem.name;
      const element = event.target as HTMLElement;

      if (element) {
        const { top } = element.getClientRects().item(0)!;

        tooltipStyle.value.top = top + 4 + "px";
        isTooltipVisible.value = true;
      }
    };
    const navigationItemMouseLeaveHandler = () => {
      mouseLeaveTimeout = setTimeout(() => {
        isTooltipVisible.value = false;
        mouseLeaveTimeout = null;
        setTimeout(() => {
          if (!isTooltipVisible.value) {
            tooltipBody.value = "";
            tooltipStyle.value.top = "";
          }
        }, 300);
      }, 300);
    };
    const isActive = (navigation: INavigation) => {
      if (navigation) {
        const path = navigation.path;

        if (path && path === "/") {
          return $route.path === "/";
        } else if (
          path === "/chat" &&
          typeof $route.name === "string" &&
          ["chat-user-page"].includes($route.name)
        ) {
          return true;
        } else if (
          path === "/contacts" &&
          typeof $route.name === "string" &&
          ["user-page"].includes($route.name)
        ) {
          return true;
        } else if (path === "/meets" && $route.fullPath.startsWith("/meets")) {
          return true;
        }

        return !!path && $route.path.split("?")[0].includes(path);
      }

      return false;
    };
    const isInBackground = (navigation: INavigation) =>
      !!$store.state.runningApps &&
      $store.state.runningApps.some(a => navigation.path.includes(a.id));

    return {
      navigationList,
      profileNavigationItem,
      notificationList,
      serviceListContainer,
      moreNavigationItem,
      isShowMoreButton,
      navigationMoreList,
      isShowMoreMenu,
      moreBottom,
      moreLeft,
      moreButton,
      isMoreBackground,
      isTooltipVisible,
      tooltipStyle,
      tooltipBody,
      isMoreBackgroundActive,
      profileNotifications,
      mainNavigationList,
      isInBackground,
      isActive,
      navigationItemMouseLeaveHandler,
      navigationItemMouseEnterHandler,
      closeMoreMenu,
      moreSelectHandler,
      moreClickHandler,
      navItemClickHandler
    };
  }
});
