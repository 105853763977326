import { Stream } from "@superapp/life-proto/pkg-ts/tnlife/chat/v3/stream_pb";

import {
  IRequestPlannedMeet,
  IRequestMessage
} from "@/infrastructure/i-infrastructure";

import { IBridgeRequest } from "./bridge/i-bridge";

/* eslint-disable no-unused-vars */

export default class Infrastructure {
  setting = {
    async Config() {
      return import("./setting/config").then(module => module.Config());
    }
  };
  auth = {
    async IsLoggedIn() {
      return import("./auth/is-logged-in").then(module => module.IsLoggedIn());
    },
    async RefreshToken() {
      return import("./auth/refresh-token").then(module =>
        module.RefreshToken()
      );
    },
    async AuthByCode(code: string) {
      return import("./auth/auth-by-code").then(module =>
        module.AuthByCode(code)
      );
    },
    async Logout() {
      return import("./auth/logout").then(module => module.Logout());
    }
  };
  file = {
    async Download(
      id: string,
      mimeType: string,
      progressCallback?: (size: number) => void
    ) {
      return import("./file/download").then(module =>
        module.Download(id, mimeType, progressCallback)
      );
    },
    async Upload(
      payload: {
        file: File;
        streamId: string;
        logo?: boolean;
        embedded?: boolean;
      },
      progressCallback?: (size: number) => void
    ) {
      return import("./file/upload").then(module =>
        module.Upload(payload, progressCallback)
      );
    },
    async Link(id: string, file?: boolean) {
      return import("./file/link").then(module => module.Link(id, file));
    }
  };
  user = {
    async GetUser() {
      return import("./user/get-user").then(module => module.GetUser());
    },
    async GetUserInfoById(id: string) {
      return import("./user/get-user-info-by-id").then(module =>
        module.GetUserInfoById(id)
      );
    },
    async GetPerson(portalCode: number) {
      return import("./user/get-person").then(module =>
        module.GetPerson(portalCode)
      );
    },
    async GetPersonColleagues(portalCode: number) {
      return import("./user/get-person-colleagues").then(module =>
        module.GetPersonColleagues(portalCode)
      );
    },
    async SetNotifyToken(playerId: string) {
      return import("./user/set-notify-token").then(module =>
        module.SetNotifyToken(playerId)
      );
    },
    async GetPresence(ids: string[]) {
      return import("./user/get-presence").then(module =>
        module.GetPresence(ids)
      );
    },
    async UpdateProfileTimezone() {
      return import("./user/update-profile-timezone").then(module =>
        module.UpdateProfileTimezone()
      );
    }
  };
  services = {
    async GetServicesList() {
      return import("./services/get-services-list").then(module =>
        module.GetServicesList()
      );
    },
    async GetSiteUrlWithAuthCode(uuid: string) {
      return import("./services/get-site-with-auth-code").then(module =>
        module.GetSiteWithAuthCode(uuid)
      );
    }
  };
  bridge = {
    async AuthCode(bridgeRequest: IBridgeRequest, uuid: string) {
      return import("./bridge/auth-code").then(module =>
        module.AuthCode(bridgeRequest, uuid)
      );
    },
    async Init(bridgeRequest: IBridgeRequest, payload?: string) {
      return import("./bridge/init").then(module =>
        module.Init(bridgeRequest, payload)
      );
    },
    async GetClientVersion(bridgeRequest: IBridgeRequest) {
      return import("./bridge/get-client-version").then(module =>
        module.GetClientVersion(bridgeRequest)
      );
    },
    async GetAppLink(bridgeRequest: IBridgeRequest, appId: string) {
      return import("./bridge/get-app-link").then(module =>
        module.GetAppLink(bridgeRequest, appId)
      );
    },
    async UploadFile(
      file: File,
      progressCallback?: (progress: number) => void
    ) {
      return import("./bridge/upload-file").then(module =>
        module.UploadFile(file, progressCallback)
      );
    },
    async Supports(bridgeRequest: IBridgeRequest) {
      return import("./bridge/supports").then(module =>
        module.Supports(bridgeRequest)
      );
    },
    async Download(bridgeRequest: IBridgeRequest) {
      return import("./bridge/download").then(module =>
        module.Download(bridgeRequest)
      );
    },
    async OpenUrl(bridgeRequest: IBridgeRequest) {
      return import("./bridge/open-url").then(module =>
        module.OpenUrl(bridgeRequest)
      );
    },
    async CopyText(bridgeRequest: IBridgeRequest) {
      return import("./bridge/copy-text").then(module =>
        module.CopyText(bridgeRequest)
      );
    },
    async OpenService(bridgeRequest: IBridgeRequest, widgetUuid: string) {
      return import("./bridge/open-service").then(module =>
        module.OpenService(bridgeRequest, widgetUuid)
      );
    }
  };
  stream = {
    async GetSubscribedList(
      firstTake: boolean = false,
      fromSecondPage: boolean = false,
      archived: boolean = false
    ) {
      return import("./stream/get-subscribed-list").then(module =>
        module.GetSubscribedList(firstTake, fromSecondPage, archived)
      );
    },
    async GetMessageList(payload: {
      streamId: string;
      messageId?: string;
      updateDate?: Date;
    }) {
      return import("./stream/get-message-list").then(module =>
        module.GetMessageList(payload)
      );
    },
    async PinStream(streamId: string) {
      return import("./stream/pin-stream").then(module => {
        return module.PinStream(streamId);
      });
    },
    async UnpinStream(streamId: string) {
      return import("./stream/unpin-stream").then(module => {
        return module.UnpinStream(streamId);
      });
    },
    async Unsubscribe(streamId: string) {
      return import("./stream/unsubscribe").then(module =>
        module.Unsubscribe(streamId)
      );
    },
    async CreateStream(payload: {
      type: Stream.Type;
      title: string;
      description: string;
      isPublic: boolean;
      usersIds: string[];
      profile?: boolean;
    }) {
      return import("./stream/create-stream").then(module =>
        module.CreateStream(payload)
      );
    },
    async SendMessage(payload: IRequestMessage) {
      return import("./stream/send-message").then(module =>
        module.SendMessage(payload)
      );
    },
    async MarkMessageAsRead(payload: { streamId: string; messageId: string }) {
      return import("./stream/mark-message-as-read").then(module =>
        module.MarkMessageAsRead(payload)
      );
    },
    async GetStream(payload: { streamId?: string; username?: string }) {
      return import("./stream/get-stream").then(module =>
        module.GetStream(payload)
      );
    },
    async Subscribe(id: string) {
      return import("./stream/subscribe").then(module => module.Subscribe(id));
    },
    async GetSubscribersList(streamId: string) {
      return import("./stream/get-subscribers-list").then(module =>
        module.GetSubscribersList(streamId)
      );
    },
    async InviteUsersToStream(payload: {
      streamId: string;
      usersId: string[];
    }) {
      return import("./stream/invite-users-to-stream").then(module =>
        module.InviteUsersToStream(payload)
      );
    },
    async DeleteUserFromStream(payload: {
      streamId: string;
      usersId: string[];
      ban?: boolean;
    }) {
      return import("./stream/delete-user-from-stream").then(module =>
        module.DeleteUserFromStream(payload)
      );
    },
    async UpdateStream(payload: {
      title?: string;
      description?: string;
      private?: boolean;
      streamId: string;
    }) {
      return import("./stream/update-stream").then(module =>
        module.UpdateStream(payload)
      );
    },
    async MeetList() {
      return import("./meet/meet-list").then(module => module.MeetList());
    },
    async SearchForInvite(query: string) {
      return import("./stream/search-for-invite").then(module =>
        module.SearchForInvite(query)
      );
    },
    async ArchiveStream(streamId: string) {
      return import("./stream/archive-stream").then(module =>
        module.ArchiveStream(streamId)
      );
    },
    async UnarchiveStream(streamId: string) {
      return import("./stream/unarchive-stream").then(module =>
        module.UnarchiveStream(streamId)
      );
    }
  };
  notifications = {
    async GetGlobalSettings() {
      return import("./notifications/get-global-settings").then(module =>
        module.GetGlobalSettings()
      );
    },
    async MarkAsRead(payload: { streamId: string; messageId: string }) {
      return import("./notifications/mark-as-read").then(module =>
        module.MarkAsRead(payload)
      );
    },
    async SetStreamSettings(payload: { streamId: string; mute: boolean }) {
      return import("./notifications/set-stream-settings").then(module =>
        module.SetStreamSettings(payload)
      );
    }
  };
  contacts = {
    async GetContactsList() {
      return import("./contacts/get-contacts-list").then(module =>
        module.GetContactsList()
      );
    },
    async RemoveContact(portalCode: number) {
      return import("./contacts/remove-contact").then(module =>
        module.RemoveContact(portalCode)
      );
    },
    async AddContact(userId: number) {
      return import("./contacts/add-contact").then(module =>
        module.AddContact(userId)
      );
    }
  };
  dynLink = {
    async GetPayload(link: string) {
      return import("./dynlink/get-payload").then(module =>
        module.GetPayload(link)
      );
    },
    async SetPayload(payload: string) {
      return import("./dynlink/set-payload").then(module =>
        module.SetPayload(payload)
      );
    }
  };
  search = {
    async GlobalSearch(query: string) {
      return import("./search/global-search").then(module =>
        module.GlobalSearch(query)
      );
    },
    async EmployeeSearch(query: string) {
      return import("./search/employee-search").then(module =>
        module.EmployeeSearch(query)
      );
    },
    async PersonSearch(query: string) {
      return import("./search/person-search").then(module =>
        module.PersonSearch(query)
      );
    }
  };
  meet = {
    async CreateMeet(payload: { streamId?: string; roomId?: string }) {
      return import("./meet/create-meet").then(module =>
        module.CreateMeet(payload)
      );
    },
    async DeclineCall(roomId: string) {
      return import("./meet/decline-call").then(module =>
        module.DeclineCall(roomId)
      );
    },
    async GetMeetURL(roomId: string) {
      return import("./meet/get-meet-url").then(module =>
        module.GetMeetURL(roomId)
      );
    },
    async EndMeet(roomId: string) {
      return import("./meet/end-meet").then(module => module.EndMeet(roomId));
    },
    async AnswerMeet(roomId: string) {
      return import("./meet/answer-meet").then(module =>
        module.AnswerMeet(roomId)
      );
    },
    async GetRecord(recordId: string) {
      return import("./meet/get-record").then(module =>
        module.GetRecord(recordId)
      );
    },
    async GetTimezones() {
      return import("./meet/get-timezones").then(module =>
        module.GetTimezones()
      );
    },
    async CreatePlannedMeet(meet: IRequestPlannedMeet) {
      return import("./meet/create-planned-meet").then(module =>
        module.CreatePlannedMeet(meet)
      );
    },
    async UpdatePlannedMeet(meet: IRequestPlannedMeet) {
      return import("./meet/update-planned-meet").then(module =>
        module.UpdatePlannedMeet(meet)
      );
    },
    async GetPlannedMeet(id: string) {
      return import("./meet/get-planned-meet").then(module =>
        module.GetPlannedMeet(id)
      );
    },
    async GetPlannedMeetList(payload: { from: Date; to: Date }) {
      return import("./meet/get-planned-meet-list").then(module =>
        module.GetPlannedMeetList(payload)
      );
    },
    async CancelMeet(id: string) {
      return import("./meet/cancel-meet").then(module => module.CancelMeet(id));
    },
    async CreateInstantMeet() {
      return import("./meet/create-instant-meet").then(module =>
        module.CreateInstantMeet()
      );
    },
    async GetMeetICS(meetId: string) {
      return import("./meet/get-meet-ics").then(module =>
        module.GetMeetICS(meetId)
      );
    },
    async CallQualityReview(payload: {
      roomId: string;
      stars: number;
      comment: string;
    }) {
      return import("./meet/call-quality-review").then(module =>
        module.CallQualityReview(payload)
      );
    }
  };
  embedded = {
    async GetStream() {
      return import("./embedded/get-stream").then(module => module.GetStream());
    },
    async GetUser(id: string) {
      return import("./embedded/get-user").then(module => module.GetUser(id));
    },
    async MarkAsRead(payload: { streamId: string; messageId: string }) {
      return import("./embedded/mark-as-read").then(module =>
        module.MarkAsRead(payload)
      );
    },
    async SendMessage(payload: IRequestMessage) {
      return import("./embedded/send-message").then(module =>
        module.SendMessage(payload)
      );
    }
  };
}
